import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Empty, Popconfirm } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const Invoice = () => {
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const tableRef = useRef(null);

  const fetchInvoices = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Please log in to view invoices.");
      navigate("/signIn");
      return;
    }

    try {
      const response = await axios.get(
        `${API_BASE_URL}/invoice/getAllInvoices`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const reversedInvoices = response.data.reverse();
      setInvoices(reversedInvoices);
      setFilteredInvoices(reversedInvoices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      toast.error("Failed to fetch invoices.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [navigate]);

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
    filterInvoicesByDate(e.target.value);
  };

  const filterInvoicesByDate = (selectedDate) => {
    if (selectedDate) {
      const filteredData = invoices.filter(
        (invoice) =>
          new Date(invoice.invoiceDate).toISOString().slice(0, 10) ===
          selectedDate
      );
      setFilteredInvoices(filteredData);
    } else {
      setFilteredInvoices(invoices);
    }
  };

  const handleDeleteInvoice = async (invoiceId) => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Please log in to delete an invoice.");
      return;
    }

    try {
      await axios.delete(`${API_BASE_URL}/invoice/deleteInvoice/${invoiceId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      // Fetch updated list of invoices after deletion
      fetchInvoices();

      toast.success("Invoice deleted successfully");
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error("Failed to delete invoice");
    }
  };

  const handleRefresh = () => {
    setFilterDate("");
    setFilteredInvoices(invoices);
  };

  const handleDownloadPDF = async () => {
    const input = tableRef.current;

    if (!input) return;

    const actionHeader = input.querySelector("th:nth-child(9)");
    const actionCells = input.querySelectorAll("td:nth-child(9)");

    if (actionHeader) actionHeader.style.display = "none";
    actionCells.forEach((cell) => (cell.style.display = "none"));

    await new Promise((resolve) => setTimeout(resolve, 100));

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgWidth = 190;
    const pageHeight = pdf.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 10;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - position;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + 10;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    if (actionHeader) actionHeader.style.display = "";
    actionCells.forEach((cell) => (cell.style.display = ""));

    pdf.save("InvoiceList.pdf");
  };

  const calculateInvoiceDetails = (invoice) => {
    const totalBeforeTax = invoice.items.reduce(
      (acc, item) => acc + parseFloat(item.itemTotalPrice),
      0
    );
    const taxAmount =
      invoice.taxes && invoice.taxes.length > 0
        ? invoice.taxes.reduce(
            (acc, tax) => acc + (totalBeforeTax * parseFloat(tax.rate)) / 100,
            0
          )
        : 0;
    const totalAfterTax = totalBeforeTax + taxAmount;

    const discount = invoice.discount
      ? (invoice.discount / 100) *
        invoice.items.reduce(
          (total, item) => total + parseFloat(item.itemTotalPrice),
          0
        )
      : 0;
    const totalAfterDiscount = totalAfterTax - discount;
    return {
      totalBeforeTax,
      taxAmount,
      totalAfterTax,
      discount,
      totalAfterDiscount,
    };
  };
  {
    loading && (
      <div className="flex justify-center items-center h-full">
        <div className="spinner"></div>
      </div>
    );
  }
  return (
    <div className="rounded-xl bg-white h-full py-3 px-4 border border-2 border-[#E9EAEB] font-customFont">
      <ToastContainer />

      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Invoices</h1>
          <p className="text-xs text-gray-500 font-semibold">
            View and manage your invoices
          </p>
        </div>
        <div className="flex gap-3 items-center mt-2 md:mt-0">
          <button
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md mb-4 md:mb-0"
            onClick={handleRefresh}
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          <button
            className="text-black border border-[#DCE3E3] px-2 py-2 rounded-md mb-4 md:mb-0 flex items-center"
            onClick={handleDownloadPDF}
          >
            <img src="../icons/Download.svg" className="w-5" alt="Download" />
            <span className="ms-2">Download PDF</span>
          </button>
          <Link to="/panel/createInvoice">
            <button className="bg-themeGradient text-white px-2 py-1 rounded-md mb-4 md:mb-0 flex items-center">
              <i className="fa-solid fa-plus text-lg"></i>
              <span className="ms-2">New Invoice</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-3 my-4">
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          <input
            type="date"
            id="filterDate"
            value={filterDate}
            onChange={handleFilterDateChange}
            className={`border border-gray-300 rounded-md py-1 px-2 w-full ${
              filterDate ? "ring-1 ring-blue-500 rounded-md" : ""
            }`}
          />
        </div>
      </div>
      {!loading && (
        <>
          <div className="relative">
            <div className="max-h-[500px] scrollbar-hide">
              <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
                <div className="min-w-[800px] sm:min-w-full rounded-lg">
                  <table
                    className="min-w-full divide-y divide-gray-200"
                    ref={tableRef}
                  >
                    <thead className="bg-[#E9EAEB] text-[#535862] font-semibold border border-gray-200">
                      <tr>
                        <th className="px-4 py-3">Company</th>
                        <th className="px-4 py-3">Customer Name</th>
                        <th className="px-4 py-3">Invoice Date</th>
                        <th className="px-4 py-3">Amount</th>
                        <th className="px-4 py-3">Tax</th>
                        <th className="px-4 py-3">Subtotal</th>
                        <th className="px-4 py-3">Discount (%)</th>
                        <th className="px-4 py-3">Total</th>
                        <th className="px-4 py-3">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredInvoices.length > 0 ? (
                        filteredInvoices.map((invoice) => {
                          const {
                            totalBeforeTax,
                            taxAmount,
                            totalAfterTax,
                            discount,
                            totalAfterDiscount,
                          } = calculateInvoiceDetails(invoice);
                          return (
                            <tr
                              key={invoice._id}
                              className="hover:bg-gray-50 transition-all duration-300 text-center border-b border-gray-200"
                            >
                              <td className="px-4 py-3">
                                {invoice.customerCompany}
                              </td>
                              <td className="px-4 py-3">
                                {invoice.customerName}
                              </td>
                              <td className="px-4 py-3">
                                {formatDate(invoice.invoiceDate)}
                              </td>
                              <td className="px-4 py-3">
                                {invoice.currencySymbol}{" "}
                                {totalBeforeTax
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="px-4 py-3">
                              {invoice.currencySymbol}{" "}
                                {taxAmount.toFixed(2)}
                              </td>
                              <td className="px-4 py-3">
                                {invoice.currencySymbol}{" "}
                                {totalAfterTax
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="px-4 py-3">
                                {invoice.discount
                                  ? `${invoice.discount + " "}%`
                                  : "0 %"}
                              </td>
                              <td className="px-4 py-3">
                                {invoice.currencySymbol}{" "}
                                {totalAfterDiscount
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="px-4 py-3">
                                <div className="flex gap-1 justify-between items-center">
                                  <Link to={`/Panel/invoice/${invoice._id}`}>
                                    <div className="border border-gray-200 bg-white rounded-md px-1 py-0.5">
                                      <i class="fa-regular fa-eye text-gray-500"></i>
                                    </div>
                                  </Link>
                                  <Link
                                    to={`/Panel/editInvoice/${invoice._id}`}
                                  >
                                    <img
                                      src="../icons/Edit.svg"
                                      className="cursor-pointer "
                                      alt="delete icon"
                                    />
                                  </Link>
                                  <Popconfirm
                                    title="Are you sure you want to delete this invoice?"
                                    onConfirm={() =>
                                      handleDeleteInvoice(invoice._id)
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      src="../icons/Delete.svg"
                                      className="cursor-pointer"
                                      alt="delete icon"
                                    />
                                  </Popconfirm>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className="py-10">
                            <Empty description="No invoices found" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Invoice;
