import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import html2pdf from "html2pdf.js";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const InvoiceDetail = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const invoiceRef = useRef();
  const [companyName, setCompanyName] = useState("");
  const [companylogo, setCompanyLogo] = useState("");
  const [companyAbout, setCompanyAbout] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("Please log in to edit the invoice.");
      return;
    }

    const fetchCompany = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/company/getCompany`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanyName(response.data.title);
        setCompanyAbout(response.data.about);
        setCompanyLogo(response.data.imagePath);
      } catch (error) {
        toast.error("Failed to fetch company details");
      }
    };

    const fetchInvoice = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/invoice/getInvoiceById/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const invoice = response.data;

        if (invoice) {
          setInvoice(invoice);
        } else {
          toast.error("Invoice not found for this company");
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        toast.error(
          error.response?.data?.message || "Failed to fetch invoice details"
        );
        setLoading(false);
      }
    };

    fetchCompany();
    fetchInvoice();
  }, [id]);

  if (loading) return;
  <div className="flex justify-center items-center h-full">
    <div class="spinner"></div>
  </div>;

  if (!invoice) return <div>Invoice not found</div>;

  const handleDownload = () => {
    const element = invoiceRef.current;
    const options = {
      margin: 0.5,
      filename: `Invoice_${id}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save();
  };

  return (
    <div className="rounded-xl bg-white h-full py-3 px-4 border border-2 border-[#E9EAEB] font-customFont">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Invoice</h1>
          <p className="text-xs text-gray-500 font-semibold">
            Edit or Download invoice
          </p>
        </div>
        <div className="flex gap-3 items-center mt-2 md:mt-0">
          <button
            className="text-black border border-[#DCE3E3] px-2 py-2 rounded-md mb-4 md:mb-0 flex items-center"
            onClick={handleDownload}
          >
            <img src="/icons/Download.svg" className="w-5" alt="Download" />
            <span className="ms-2">Download Invoice</span>
          </button>
        </div>
      </div>
      <div ref={invoiceRef}>
        <div className="text-center mb-4">
          <h2 className="text-2xl font-bold">{companyName}</h2>
          <h2 className="text-lg font-semibold text-[#535862]">
            {companyAbout}
          </h2>
        </div>
        <div className="flex justify-between me-10 my-4">
          <div>
            {invoice.invoiceNumber ? (
              <div className="my-4">
                <strong className="me-2">Invoice #</strong>

                <span className="text-[#535862]">{invoice.invoiceNumber}</span>
              </div>
            ) : null}
            {invoice.customerCompany ? (
              <div className="my-4">
                <strong className="me-2">Company:</strong>

                <span className="text-[#535862]">
                  {invoice.customerCompany}
                </span>
              </div>
            ) : null}

            {invoice.customerName ? (
              <div className="my-4">
                <strong className="me-2">Customer Name:</strong>

                <span className="text-[#535862]">{invoice.customerName}</span>
              </div>
            ) : null}

            <div className="mb-4">
              <strong className="me-2">Invoice Date:</strong>

              <span className="text-[#535862]">
                {new Date(invoice.invoiceDate).toLocaleDateString()}
              </span>
            </div>
          </div>
          <div>
            <img src={companylogo} className="w-36 h-36" />
          </div>
        </div>

        <div className="mb-4">
          <div className="overflow-x-auto mt-2 rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200 rounded-2xl border"
              style={{ borderTopLeftRadius: "20px" }}
            >
              <thead className="bg-[#E9EAEB] text-[#535862]">
                <tr>
                  <th className="px-4 py-3">Item Name</th>
                  <th className="px-4 py-3">Quantity</th>
                  <th className="px-4 py-3">Unit Price</th>
                  <th className="px-4 py-3">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {invoice.items.map((item, index) => (
                  <tr key={index} className="border-b text-center">
                    <td className="px-4 py-3">{item.itemName}</td>
                    <td className="px-4 py-3">{item.itemQuantity}</td>
                    <td className="px-4 py-3">
                      {invoice.currencySymbol}{" "}
                      {parseFloat(item.itemUnitPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td className="px-4 py-3">
                      {invoice.currencySymbol}{" "}
                      {parseFloat(item.itemTotalPrice)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="mb-4">
          <span>{invoice.note || ""}</span>
        </div>
        <div className="flex justify-between mx-20">
          {!invoice.signature ? (
            <div className="w-44 h-32 mt-5 me-10">
              <img src="/signaturePlaceholder.png" alt="SignaturePlaceholder" />
            </div>
          ) : (
            <div className="w-44 mt-5 me-10 overflow-hidden">
              <img
                src={invoice.signature}
                alt="Signature"
                className="w-full object-cover rounded-lg"
              />
            </div>
          )}

          <div>
            <div className="flex justify-between w-44">
              <strong className="me-2">Subtotal:</strong>
              <span className="text-[#535862]">
                {invoice.currencySymbol}{" "}
                {invoice.items
                  .reduce(
                    (total, item) => total + parseFloat(item.itemTotalPrice),
                    0
                  )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
              </span>
            </div>
            <div>
              {invoice.taxes.length > 0 ? (
                invoice.taxes.map((tax) => (
                  <div key={tax.name} className="flex justify-between">
                    <strong className="me-2">
                      {tax.name.charAt(0).toUpperCase() + tax.name.slice(1)}:
                    </strong>
                    <span className="text-[#535862]">{tax.rate}%</span>
                  </div>
                ))
              ) : (
                <div className="flex justify-between">
                  <strong className="me-2">Tax:</strong>
                  <span className="text-[#535862]">0%</span>
                </div>
              )}
            </div>

            {invoice.discount > 0 ? (
              <div className="flex justify-between w-44">
                <strong className="me-2">Discount:</strong>
                <span className="text-[#535862]">{invoice.discount}%</span>
              </div>
            ) : (
              <div className="flex justify-between w-44">
                <strong className="me-2">Discount:</strong>
                <span className="text-[#535862]">0%</span>
              </div>
            )}

            <div className="mb-4 border-t border-[#535862] pt-2 mt-2 justify-between flex">
              <strong className="me-2">Total:</strong>
              <span className="text-[#535862]">
  {invoice.currencySymbol}{" "}
  {(
    // Calculate the total price of items
    invoice.items.reduce(
      (total, item) => total + parseFloat(item.itemTotalPrice),
      0
    ) *
    // Calculate the total tax rate by summing up all tax rates in the tax list
    (invoice.taxes && invoice.taxes.length > 0
      ? invoice.taxes.reduce(
          (taxTotal, tax) => taxTotal + parseFloat(tax.rate) / 100,
          0
        ) + 1
      : 1) -
    // Apply the discount if it exists
    (invoice.discount
      ? (invoice.discount / 100) *
        invoice.items.reduce(
          (total, item) => total + parseFloat(item.itemTotalPrice),
          0
        )
      : 0)
  )
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
</span>

            </div>
          </div>
        </div>
        {/* Footer Section */}
        <div
          style={{
            textAlign: "left",
            padding: "10px",
            fontSize: "10px",
            color: "#535862",
            zIndex: 1000, // Ensures it stays on top
          }}
          className="justify-end flex flex-col items-end mt-20"
        >
          <div className="text-left">
            <div>
              <strong>DG-Expense</strong>
            </div>
            <div>
              Powered by <strong>Devsouq Technologies</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetail;
