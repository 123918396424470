import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Popconfirm, Select, message } from "antd";
import axios from "axios";
import AddSubUser from "../components/AddSubUser";

const { Option } = Select;

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const Team = () => {
  const [userData, setUserData] = useState(null);
  const [subUsers, setSubUsers] = useState([]);
  const [filteredSubUsers, setFilteredSubUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addSubUserModalVisible, setAddSubUserModalVisible] = useState(false);
  const [project, setProject] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState(true);
  const [projects, setProjects] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [editedData, setEditedData] = useState({});

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
      }
    };

    fetchProjects();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error("Not authenticated");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { data: userProfile } = await axios.get(
          `${API_BASE_URL}/auth/profile`,
          config
        );
        setUserData(userProfile);

        const { data: subUsersData } = await axios.get(
          `${API_BASE_URL}/subusers/getAllSubUsers`,
          config
        );
        setSubUsers(subUsersData);
        const matchingSubUsers = subUsersData.filter(
          (subUser) => subUser.parentUser === userProfile._id
        );
        setFilteredSubUsers(matchingSubUsers);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const { data: userProfile } = await axios.get(
              `${API_BASE_URL}/subusers/subuserprofile`,
              config
            );
            setUserData(userProfile);

            const { data: subUsersData } = await axios.get(
              `${API_BASE_URL}/subusers/getAllSubUsers`,
              config
            );
            setSubUsers(subUsersData);
            const matchingSubUsers = subUsersData.filter(
              (subUser) =>
                subUser.parentUser === userProfile._id ||
                subUser.status === "true"
            );
            setFilteredSubUsers(matchingSubUsers);
          } catch (error) {
            toast.error("Failed to fetch user profile from subuser endpoint");
          }
        } else {
          toast.error("Failed to fetch user data");
        }
      } finally {
        setLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch user data");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleAddSubUser = () => {
    setAddSubUserModalVisible(true);
  };

  const handleModalClose = () => {
    setAddSubUserModalVisible(false);
  };

  const handleSuccess = () => {
    setAddSubUserModalVisible(false);
    fetchUserData();
  };

  const handleDeleteSubUser = async (subUserId) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      await axios.delete(
        `${API_BASE_URL}/subusers/deleteSubUser/${subUserId}`,
        config
      );
      toast.success("Sub-user deleted successfully");
      fetchUserData();
    } catch (error) {
      toast.error("Failed to delete sub-user");
    }
  };

  const handleProjectChange = (value) => {
    setProject(value);
    if (value) {
      filterSubUsers(value, role, status);
    } else {
      setFilteredSubUsers(subUsers);
    }
  };

  const handleRoleChange = (value) => {
    setRole(value);
    if (value) {
      filterSubUsers(project, value, status);
    } else {
      setFilteredSubUsers(subUsers);
    }
  };

  const handleStatusChange = (value) => {
    const newStatus = value === "true";
    setStatus(newStatus);
    filterSubUsers(project, role, newStatus);
  };

  useEffect(() => {
    filterSubUsers(project, role, status);
  }, [project, role, status, subUsers]);

  const filterSubUsers = (project, role, status) => {
    let filtered = subUsers.filter(
      (subUser) => subUser.parentUser === userData?._id
    );
    if (project) {
      filtered = filtered.filter((subUser) => subUser.project === project);
    }
    if (role) {
      filtered = filtered.filter((subUser) => subUser.role === role);
    }
    if (status !== null) {
      filtered = filtered.filter((subUser) => subUser.status === status);
    }
    setFilteredSubUsers(filtered);
  };

  useEffect(() => {
    filterSubUsers(project, role, status);
  }, [project, role, status]);

  const handleRefresh = () => {
    setProject("");
    setRole("");
    setStatus(null);
    setFilteredSubUsers(subUsers);
    fetchUserData();
  };

  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditedData({
      name: user.name,
      email: user.email,
      project: user.project,
      role: user.role,
      status: user.status,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleUpdateSubUser = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data: updatedUser } = await axios.put(
        `${API_BASE_URL}/subusers/updateSubUser/${editingUser._id}`,
        editedData,
        config
      );
      toast.success("Sub-user updated successfully");
      fetchUserData();
      setEditingUser(null);
    } catch (error) {
      toast.error("Failed to update sub-user");
    }
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div class="spinner"></div>
      </div>
    );
    
  return (
    <div className="rounded-xl bg-white h-full py-3 px-4 border border-2 border-[#E9EAEB] font-customFont">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Team</h1>
          <p className="text-xs text-gray-500 font-semibold">
            Manage your team
          </p>
        </div>
        <div className="flex gap-3 items-center mt-2 md:mt-0">
          {" "}
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md mb-4 md:mb-0"
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          <button
            className="bg-themeGradient text-white px-2 py-1 rounded-md mb-4 md:mb-0 flex items-center"
            onClick={handleAddSubUser}
          >
            <i className="fa-solid fa-plus text-xl"></i>
            <span className="font-semibold ms-2">Add Member</span>
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-3 my-4">
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          <Select
            id="status"
            value={status ? "Active" : "Inactive"}
            onChange={handleStatusChange}
            className={`w-full rounded-md ${
              status ? "ring-1 ring-blue-400" : ""
            }`}
          >
            <Option value="true">Active</Option>
            <Option value="false">Inactive</Option>
          </Select>
        </div>
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          <Select
            id="project"
            value={project}
            onChange={handleProjectChange}
            className={`w-full rounded-md ${
              project ? "ring-1 ring-blue-400 rounded-md" : ""
            }`}
          >
            <Option value="">Projects</Option>
            {projects.map((project) => (
              <Option key={project._id} value={project.project}>
                {project.project}
              </Option>
            ))}
          </Select>
        </div>
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          <Select
            id="role"
            value={role}
            onChange={handleRoleChange}
            className={`w-full rounded-md ${
              role ? "ring-1 ring-blue-400 rounded-md" : ""
            }`}
          >
            <Option value="">Roles</Option>
            <Option value="View">View</Option>
            <Option value="Edit">Edit</Option>
            <Option value="Admin">Admin</Option>
          </Select>
        </div>
      </div>
      <div className="relative">
        <div className="max-h-[500px] scrollbar-hide">
          <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
            <div className="min-w-[800px] sm:min-w-full rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-[#E9EAEB] text-[#535862] font-semibold border border-gray-200">
                  <tr className="border-b-2">
                    <th className="py-3 px-4 text-left">
                      <span>User</span>
                    </th>
                    <th className="py-3 px-4 text-left">
                      <span>Project</span>
                    </th>
                    <th className="py-3 px-4 text-left">
                      <span>Role</span>
                    </th>
                    <th className="py-3 px-4 text-left">
                      <span>Email</span>
                    </th>
                    <th className="py-3 px-4 text-left">
                      <span>Status</span>
                    </th>
                    <th className="py-3 px-4 text-right">
                      <span>Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {filteredSubUsers.length > 0 ? (
                    filteredSubUsers.map((user) => (
                      <tr
                        key={user._id}
                        className="hover:bg-gray-50 transition-colors duration-200 border-b bg-white divide-y divide-gray-200 text-[#535862] border border-gray-200"
                      >
                        <td className="py-4 px-4">
                          {editingUser && editingUser._id === user._id ? (
                            <input
                              type="text"
                              name="name"
                              value={editedData.name}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                          ) : (
                            <div className="flex items-center">
                              <div className="w-8 h-8 me-2 rounded-full bg-[#F5F5F5] flex items-center justify-center text-[#535862] border border-[#E9EAEB] font-bold">
                                {user.name.charAt(0).toUpperCase()}
                              </div>
                              {user.name}
                            </div>
                          )}
                        </td>
                        <td className="py-4 px-4">
                          {editingUser && editingUser._id === user._id ? (
                            <input
                              type="text"
                              name="project"
                              value={editedData.project}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                          ) : (
                            <div className="flex gap-2">
                              <span>
                                <img src="../icons/Projects.svg" />
                              </span>
                              <span>{user.project}</span>
                            </div>
                          )}
                        </td>
                        <td className="py-4 px-4">
                          {editingUser && editingUser._id === user._id ? (
                            <input
                              type="text"
                              name="role"
                              value={editedData.role}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                          ) : (
                            user.role
                          )}
                        </td>
                        <td className="text-left py-4 px-4">
                          {editingUser && editingUser._id === user._id ? (
                            <input
                              type="email"
                              name="email"
                              value={editedData.email}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                          ) : (
                            user.email
                          )}
                        </td>
                        <td className="py-4 px-4">
                          {editingUser && editingUser._id === user._id ? (
                            <div className="flex items-center">
                              <div
                                className={`relative inline-block w-11 h-5 transition duration-200 ease-linear rounded-full cursor-pointer ${
                                  editedData.status
                                    ? "bg-[#00B252]"
                                    : "bg-gray-300"
                                }`}
                                onClick={() =>
                                  setEditedData({
                                    ...editedData,
                                    status: !editedData.status,
                                  })
                                }
                              >
                                <span
                                  className={`absolute block w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
                                    editedData.status
                                      ? "translate-x-6"
                                      : "translate-x-0"
                                  }`}
                                ></span>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div
                                className={`relative inline-block w-11 h-5 transition duration-200 ease-linear rounded-full cursor-default ${
                                  user.status ? "bg-[#00B252]" : "bg-gray-300"
                                }`}
                              >
                                <span
                                  className={`absolute block w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
                                    user.status
                                      ? "translate-x-6"
                                      : "translate-x-0"
                                  }`}
                                ></span>
                              </div>
                            </div>
                          )}
                        </td>
                        <td className="text-right py-4 px-4 space-x-2">
                          {editingUser && editingUser._id === user._id ? (
                            <>
                              <button
                                onClick={handleUpdateSubUser}
                                className="text-gray-600 text-lg"
                              >
                                <i className="fa-regular fa-floppy-disk"></i>
                              </button>
                              <button
                                onClick={() => setEditingUser(null)}
                                className="text-gray-600 text-lg"
                              >
                                <i className="fa-regular fa-circle-xmark"></i>
                              </button>
                            </>
                          ) : (
                            <div className="flex gap-1 justify-end">
                              <img
                                src="../icons/Edit.svg"
                                className="cursor-pointer"
                                onClick={() => handleEditClick(user)}
                                alt="edit icon"
                              />
                              {!editingUser && (
                                <Popconfirm
                                  title="Are you sure you want to delete this sub-user?"
                                  onConfirm={() =>
                                    handleDeleteSubUser(user._id)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src="../icons/Delete.svg"
                                    className="cursor-pointer"
                                    alt="delete icon"
                                  />
                                </Popconfirm>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <div className="flex flex-col items-center justify-center my-10">
                          <i className="fa-solid fa-user-large-slash text-gray-400 text-5xl"></i>
                          <p className="text-gray-400 mt-4">
                            There is no SubUser
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={addSubUserModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <AddSubUser onSuccess={handleSuccess} projects={projects} />
      </Modal>
    </div>
  );
};

export default Team;
