import React, { useState, useEffect } from "react";
import { Button, Form, Input, Typography, message } from "antd";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
const { Text } = Typography;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const LoggedInUserPopup = () => {
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubUser, setIsSubUser] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [title, setTitle] = useState("");
  const [about, setAbout] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(
    companyInfo?.imagePath || ""
  );
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authenticated");
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        try {
          const { data } = await axios.get(
            `${API_BASE_URL}/auth/profile`,
            config
          );
          setUserData(data);
          setUserRole(data.role || "Admin");

          if ("role" in data && data.role) {
            const { data: subUserData } = await axios.get(
              `${API_BASE_URL}/subUsers/subUserProfile`,
              config
            );
            setUserData(subUserData);
            setUserRole(userData.role || "");
            setIsSubUser(true);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            try {
              const { data: subUserProfile } = await axios.get(
                `${API_BASE_URL}/subUsers/subUserProfile`,
                config
              );
              setUserData(subUserProfile);
              setUserRole(subUserProfile.role || "");
              setIsSubUser(true);
            } catch (subUserError) {
              toast.error("Failed to fetch sub-user data");
              console.error(subUserError);
            }
          } else {
            toast.error("Failed to fetch user profile");
            console.error(error);
          }
        }
        setLoading(false);
      } catch (error) {
        message.error("Failed to fetch user data");
        setLoading(false);
      }
    };
    const fetchCompany = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authenticated");
          setLoading(false);
          return;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const { data } = await axios.get(
          `${API_BASE_URL}/company/getCompany`,
          config
        );
        setCompanyInfo(data);
        if (data) {
          setTitle(data.title);
          setAbout(data.about);
          setImagePreview(data.imagePath);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();
    fetchCompany();
  }, []);

  const handleUpdatePasswordClick = () => {
    setShowPasswordUpdate(true);
  };

  const handleSavePassword = async (values) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error("Not authenticated");
        return;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (isSubUser) {
        const { data } = await axios.put(
          `${API_BASE_URL}/subUsers/updatePassword`,
          { password: values.password },
          config
        );
        message.success(data.message);
      } else {
        const { data } = await axios.put(
          `${API_BASE_URL}/auth/updatePassword`,
          { password: values.password },
          config
        );
        message.success(data.message);
      }

      setShowPasswordUpdate(false);
    } catch (error) {
      message.error("Failed to update password");
    }
  };

  const handleCancelUpdate = () => {
    setShowPasswordUpdate(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    toast.success("Logged out successfully!");
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 1000);
  };

  const handleAddUpdate = async () => {
    const token = localStorage.getItem("token");

    try {
      console.log("Saving data...");
      let imageUrl = imagePreview;
      if (selectedImage) {
        console.log("Uploading image...");
        imageUrl = await uploadImage(selectedImage);
        console.log("Image uploaded successfully:", imageUrl);
      }

      console.log("Sending data to the server...");
      const response = await fetch(`${API_BASE_URL}/company/addEditCompany`, {
        method: companyInfo ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ title, about, imagePath: imageUrl }),
      });

      if (!response.ok) {
        throw new Error("Failed to save company info");
      }

      const result = await response.json();
      console.log("Company Info saved successfully:", result);
      toast.success("Company Info saved successfully");
      closeModal();
    } catch (error) {
      console.error("Error saving company info:", error);
      toast.error("Error saving company info");
    }
  };

  const openEditModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ExpenseTracker");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dqvgbgje8/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await response.json();
      return urlData.url;
    } catch (error) {
      console.error(error);
      toast.error("Image upload failed.");
      return null;
    }
  };
  return (
    <div className="w-52 bg-white">
      {userRole === "Admin" || userRole === "All" ? (
        <div
          className="flex justify-between items-center cursor-pointer text-black border-b border-gray-300 mb-2 pb-2"
          onClick={openEditModal}
        >
          <span>Add/Edit Company Info</span>
          <i className="fa-solid fa-angle-right"></i>
        </div>
      ) : null}
      {!showPasswordUpdate ? (
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={handleUpdatePasswordClick}
        >
          <span>Update Password</span>
          <i className="fa-solid fa-angle-right"></i>
        </div>
      ) : (
        <div>
          <Form onFinish={handleSavePassword}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
              className="mb-4"
            >
              <Input.Password
                placeholder="New Password"
                className="w-full py-2 px-3 rounded-lg border border-gray-300"
              />
            </Form.Item>
            <div className="flex gap-2">
              <button
                type="default"
                className="w-full bg-gray-300 text-gray-700 py-2 rounded-lg hover:bg-gray-200 transition duration-300"
                onClick={handleCancelUpdate}
              >
                Cancel
              </button>
              <button
                htmlType="submit"
                className="w-full bg-themeGradient text-white py-2 rounded-lg"
              >
                Save
              </button>
            </div>
          </Form>
        </div>
      )}
      <div
        className="text-[#EB4143] border-t border-gray-300 mt-2 pt-2 text-center cursor-pointer"
        onClick={handleLogout}
      >
        <i className="fa-solid fa-arrow-right-from-bracket me-2"></i>Logout
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow-lg z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-lg font-semibold mb-4 pb-3 border-b">
          {companyInfo ? "Edit Company Info" : "Add Company Info"}
        </h2>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddUpdate();
          }}
        >
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="title">
              Company Name
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="about">
              About
            </label>
            <textarea
              id="about"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="image">
              Company Image
            </label>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
          </div>
          {imagePreview && (
            <img src={imagePreview} alt="preview" className="mb-4 w-24 h-24" />
          )}
          <div className="flex justify-end gap-2">
            <button
              type="button"
              className="bg-gray-300 rounded-md p-2"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-themeGradient rounded-md p-2 text-white"
            >
              {companyInfo ? "Update" : "Save"}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LoggedInUserPopup;
