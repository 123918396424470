import React, { useState, useEffect } from "react";
import Home from "./pages/Home";
import LaunchScreen from "./pages/LaunchScreen";
import { jwtDecode } from "jwt-decode";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AddExpense from "./pages/AddExpense";
import ExpenseList from "./pages/ExpenseList";
import ProjectDetail from "./pages/ProjectDetail";
import Chat from "./pages/Chat";
import Projects from "./pages/Projects";
import Team from "./pages/Team";
import Invoice from "./pages/Invoice";
import CreateInvoice from "./pages/CreateInvoice";
import InvoiceDetail from "./pages/InvoiceDetail";
import EditInvoice from "./pages/EditInvoice";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          setIsLoggedIn(true);
        } else {
          localStorage.removeItem("token");
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Invalid token", error);
        localStorage.removeItem("token");
        setIsLoggedIn(false);
      }
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div>
      <Routes>
        {!isLoggedIn ? (
          <Route path="/" element={<LaunchScreen />} />
        ) : (
          <Route path="/" element={<Navigate to="/Panel/dashboard" />} />
        )}

        <Route path="/Panel/*" element={<Home />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="addExpense" element={<AddExpense />} />
          <Route path="expenseList" element={<ExpenseList />} />
          <Route path="projects" element={<Projects />} />
          <Route path="chats" element={<Chat />} />
          <Route path="team" element={<Team />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="invoice/:id" element={<InvoiceDetail />} />
          <Route path="editInvoice/:id" element={<EditInvoice />} />
          <Route path="createInvoice" element={<CreateInvoice />} />
          <Route path="project/:project" element={<ProjectDetail />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
