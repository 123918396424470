import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const SiderProjectList = () => {
  const [project, setProject] = useState("");
  const [startDate, setStartDate] = useState("");
  const [currency, setCurrency] = useState("");
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [subUserProject, setSubUserProject] = useState("");
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserRole(userProfile.role || "Admin");
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserRole(subUserProfile.role || "");

          if (
            subUserProfile.role === "Edit" ||
            subUserProfile.role === "View"
          ) {
            setSubUserProject(subUserProfile.project);
          }
        } catch (subUserError) {
          toast.error("Failed to fetch sub-user data");
          console.error(subUserError);
        }
      } else {
        toast.error("Failed to fetch user profile");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleProjectChange = (event) => {
    setProject(event.target.value);
  };

  const handleDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleSaveProject = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to add a project.");
      navigate("/signIn");
      return;
    }

    if (!project || !currency || !startDate) {
      toast.error("Please provide both project name and currency.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/projects/addProject`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ project, projectCurrency: currency }),
      });

      if (response.ok) {
        toast.success("Project added successfully!");
        setProject("");
        setCurrency("");
        setStartDate("");
        setIsProjectModalOpen(false);

        fetchProjects();
      } else {
        const errorData = await response.json();
        toast.error(`Failed to add project: ${errorData.message}`);
      }
    } catch (error) {
      toast.error("An error occurred while adding project.");
      console.error("Add project error:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/projects/getProjectList`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Fetched Projects:", data.projects);
        setProjects(data || []);
      } else {
        const errorData = await response.json();
        toast.error(`Failed to fetch projects: ${errorData.message}`);
      }
    } catch (error) {
      toast.error("An error occurred while fetching projects.");
      console.error("Fetch projects error:", error);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);
  return (
    <div className="ms-3 mt-4 font-customFont">
      <div className="flex justify-between ">
        <h6 className="font-semibold text-gray-800 text-sm">PROJECTS</h6>
        {userRole === "Admin" || userRole === "VieAllw" ? (
          <img
            src="/icons/AddGroup.svg"
            className="w-5 h-5 cursor-pointer"
            onClick={() => setIsProjectModalOpen(true)}
          />
        ) : null}
      </div>
      <div>
        {userRole === "Edit" || userRole === "View" ? (
          Array.isArray(projects) && projects.length > 0 ? (
            <ul className="space-y-2">
              {projects.map(
                (projectItem) =>
                  projectItem.project === subUserProject && (
                    <Link
                      key={projectItem._id}
                      to={`/Panel/project/${encodeURIComponent(
                        projectItem.project
                      )}`}
                      className="flex mt-2 items-center ms-2 cursor-pointer"
                    >
                      <img
                        src="/icons/projectList.svg"
                        className="w-5 me-2 text-gray"
                      />
                      <span className="text-[#535862]">
                        {projectItem.project}
                      </span>
                    </Link>
                  )
              )}
            </ul>
          ) : null
        ) : (
          Array.isArray(projects) &&
          projects.length > 0 && (
            <ul className="space-y-2">
              {projects.map((projectItem) => (
                <Link
                  key={projectItem._id}
                  to={`/Panel/project/${encodeURIComponent(
                    projectItem.project
                  )}`}
                  className="flex mt-2 items-center ms-2 cursor-pointer"
                >
                  <img
                    src="/icons/projectList.svg"
                    className="w-5 me-2 text-gray"
                  />
                  <span className="text-[#535862]">{projectItem.project}</span>
                </Link>
              ))}
            </ul>
          )
        )}
      </div>

      {isProjectModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1050]">
          <div className="bg-white rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-semibold mb-4 text-black border-b-2 border-gray-300 mx-3 pt-2">
              Add New Project
            </h3>

            <div className="px-4">
              <input
                type="date"
                value={startDate}
                onChange={handleDateChange}
                className="mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                placeholder="Enter Project Name"
              />
              <input
                type="text"
                value={project}
                onChange={handleProjectChange}
                className="mt-1 mb-2 block w-full px-3 py-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                placeholder="Enter Project Name"
              />
              <div className="mb-4">
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded text-gray-400 shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <option value="">Select Currency</option>
                  <option value="USD">$</option>
                  <option value="GBP">£</option>
                  <option value="EUR">€</option>
                  <option value="SAR">﷼</option>
                  <option value="PKR">Rs</option>
                </select>
              </div>
            </div>
            <div
              className="flex justify-end mt-2 pb-2 pt-2 pe-2"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <button
                onClick={() => setIsProjectModalOpen(false)}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveProject}
                className="bg-themeGradient text-white px-4 py-2 rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default SiderProjectList;
