import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import Modal from "react-modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const socket = io(API_BASE_URL);

const Chat = () => {
  const [userData, setUserData] = useState(null);
  const [isSubUser, setIsSubUser] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [filteredSubUsers, setFilteredSubUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [group, setGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [parentUser, setParentUser] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Please log in to access the chat.");
        navigate("/signIn");
        return;
      }
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let response;
        try {
          response = await axios.get(`${API_BASE_URL}/auth/profile`, config);
          setIsSubUser(false);
          setUserRole("Admin");
        } catch (error) {
          response = await axios.get(
            `${API_BASE_URL}/subusers/subuserprofile`,
            config
          );
          setIsSubUser(true);
          setUserRole(response.data.role || "");
        }
        setUserData(response.data);
        setLoggedInUserId(response.data._id);
        const userGroups = await fetchGroupsForUser(token, response.data._id);
        setGroups(userGroups);
        if (!response.data.isSubUser) {
          const subUsersResponse = await axios.get(
            `${API_BASE_URL}/subUsers/getAllSubUsers`,
            config
          );
          const formattedSubUsers = subUsersResponse.data
            .filter((subUser) => subUser.parentUser === response.data._id)
            .map((subUser) => ({
              value: subUser._id,
              label: subUser.name || "Unknown User",
            }));

          setFilteredSubUsers(formattedSubUsers);
        } else {
          const subUsersResponse = await axios.get(
            `${API_BASE_URL}/subUsers/getAllSubUsers`,
            config
          );
          const formattedSubUsers = subUsersResponse.data
            .filter(
              (subUser) => subUser.parentUser === response.data.parentUser
            )
            .map((subUser) => ({
              value: subUser._id,
              label: subUser.name || "Unknown User",
            }));
          setFilteredSubUsers(formattedSubUsers);
        }
      } catch (error) {
        toast.error("Failed to fetch user data.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, [navigate, loggedInUserId]);

  useEffect(() => {
    if (userData?.parentUser) {
      const fetchParentUser = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await fetch(`${API_BASE_URL}/auth/getAllUsers`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          if (!response.ok) {
            throw new Error("Failed to fetch users");
          }
          const users = await response.json();
          const matchedParent = users.find(
            (user) => user._id === userData.parentUser
          );

          if (matchedParent) {
            setParentUser(matchedParent);
          } else {
            toast.error("Parent user not found");
          }
        } catch (error) {
          console.error("Failed to fetch parent user:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchParentUser();
    } else {
      setLoading(false);
    }
  }, [userData]);

  const fetchGroupsForUser = async (token, userId) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${API_BASE_URL}/group/`, config);
      const userGroups = response.data.filter((group) =>
        group.members.some((member) => member.memberId === userId)
      );
      return userGroups;
    } catch (error) {
      toast.error("Failed to fetch groups.");
      return [];
    }
  };

  const handleSaveGroup = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to add a group.");
      navigate("/signIn");
      return;
    }
    if (!group) {
      toast.error("Group name is required.");
      return;
    }
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const validMembers = selectedMembers.filter(
        (member) => member && member.value
      );
      const membersData = [
        { memberId: userData._id, memberType: "User" },
        ...validMembers.map((member) => ({
          memberId: member.value,
          memberType: "SubUser",
        })),
      ];
      const groupData = {
        name: group,
        members: membersData,
        creatorId: userData._id,
      };
      await axios.post(`${API_BASE_URL}/group/`, groupData, config);
      toast.success("Group created successfully!");

      const updatedGroups = await fetchGroupsForUser(token, userData._id);
      setGroups(updatedGroups);

      setGroup("");
      setSelectedMembers([]);
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Failed to create group");
      console.error(
        "Error details:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleMemberChange = (value) => {
    const selected = filteredSubUsers.filter((subUser) =>
      value.includes(subUser.value)
    );
    setSelectedMembers(selected);
  };

  useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem("token");
      if (!activeChat || !activeChat.chat) {
        return;
      }
      const headers = { Authorization: `Bearer ${token}` };

      try {
        if (activeChat.type === "group") {
          const response = await axios.get(
            `${API_BASE_URL}/message/group/${activeChat.chat._id}`,
            { headers }
          );
          const groupMessages = response.data.filter(
            (message) => message.groupId === activeChat.chat._id
          );
          setMessages(groupMessages);
        } else {
          const chatId = activeChat?.chat?._id || activeChat?.chat?.value;
          const response = await axios.get(
            `${API_BASE_URL}/message/inbox/${chatId}/${loggedInUserId}`,
            { headers }
          );

          const directMessages = response.data.filter(
            (message) =>
              message.groupId === null &&
              ((message.senderId === chatId &&
                message.receiverId === loggedInUserId) ||
                (message.senderId === loggedInUserId &&
                  message.receiverId === chatId))
          );
          setMessages(directMessages);
        }
      } catch (error) {
        toast.error("Failed to fetch messages.");
      }
    };

    fetchMessages();
  }, [activeChat, loggedInUserId]);

  useEffect(() => {
    socket.on("receiveMessage", (message) => {
      if (
        activeChat.type === "group" &&
        message.groupId === activeChat.chat._id
      ) {
        setMessages((prevMessages) => [...prevMessages, message]);
      } else if (
        activeChat.type !== "group" &&
        message.receiverId === loggedInUserId
      ) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });

    return () => {
      socket.off("receiveMessage");
    };
  }, [activeChat, loggedInUserId]);

  const handleSendMessage = async () => {
    const token = localStorage.getItem("token");

    if (!currentMessage) {
      toast.error("Message cannot be empty.");
      return;
    }

    const messageData = {
      senderId: loggedInUserId,
      senderName: userData.name,
      senderType: isSubUser ? "SubUser" : "User",
      msg: currentMessage,
    };

    if (activeChat.type === "group" && activeChat?.chat?._id) {
      messageData.groupId = activeChat.chat._id;
      messageData.receiverId = null;
    } else {
      messageData.receiverId = activeChat.chat._id || activeChat.chat.value;
      messageData.groupId = null;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/message/`,
        messageData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      socket.emit("sendMessage", response.data);

      if (activeChat.type !== "group") {
        setMessages((prevMessages) => [...prevMessages, response.data]);
      }

      setCurrentMessage("");
    } catch (error) {}
  };

  const customSelectStyles = {
    container: (provided) => ({
      ...provided,
      height: "50px",
    }),
    control: (provided) => ({
      ...provided,
      height: "100%",
      borderRadius: "0",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0",
    }),
  };

  const openEditModal = (project) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredResults = filteredSubUsers.filter((subUser) =>
    subUser.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full mx-auto bg-white h-full rounded-xl border border-2 border-[#E9EAEB] font-customFont">
      <div className="flex w-full h-full rounded">
        {/* Left Sidebar: List */}
        <div id="plist" className="w-1/4 p-3 h-full overflow-y-auto">
          <div
            className="flex justify-between mb-6"
            style={{ fontFamily: "Roboto, sans-serif" }}
          >
            <h6 className="font-bold text-black text-2xl">Chat</h6>
            {userData && userRole === "Admin" && (
              <img
                src="../icons/AddGroup.svg"
                alt="icon"
                onClick={() => setIsModalOpen(true)}
              />
            )}
          </div>
          {userData && userRole === "Admin" && (
            <>
              <div className="flex items-center mt-2">
                <div className="relative w-full">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                    <i className="fa fa-search" />
                  </span>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="pl-10 pr-4 py-2 w-full bg-[#F5F5F5] rounded-lg text-sm focus:outline-none focus:ring focus:ring-themeColor3"
                    placeholder="Search..."
                  />
                </div>
              </div>
              <ul className="mt-2 pb-2 space-y-3  border-b-2">
                {filteredResults.length > 0
                  ? filteredResults.map((subUser, index) => (
                      <li
                        key={index}
                        onClick={() =>
                          setActiveChat({ type: "subuser", chat: subUser })
                        }
                        className="flex items-center justify-between p-2 bg-white rounded-lg hover:bg-[#E9EAEB]"
                      >
                        <div className="flex items-center">
                          <div className="w-10 h-10 bg-[#F5F5F5] border border-[#E9EAEB] flex items-center justify-center rounded-full">
                            <span className="font-semibold text-xl text-[#535862]">
                              {subUser.label.charAt(0) || "?"}
                            </span>
                          </div>
                          <div className="ml-3">
                            <p className="font-semibold text-black">
                              {subUser.label || "Unknown User"}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </>
          )}
          <ul className="mt-2 space-y-3 ">
            {groups.length > 0
              ? groups.map((group) => (
                  <li
                    key={group._id}
                    onClick={() =>
                      setActiveChat({ type: "group", chat: group })
                    }
                    className="flex items-center justify-between p-2 bg-white rounded-lg hover:bg-[#E9EAEB]"
                  >
                    <div className="flex items-center">
                      <div className="w-10 h-10 bg-[#F5F5F5] border border-[#E9EAEB] flex items-center justify-center rounded-full">
                        <span className="font-semibold text-xl text-[#535862]">
                          <i class="fa-solid fa-users"></i>
                        </span>
                      </div>
                      <div className="ml-3">
                        <p className="font-semibold text-black">{group.name}</p>
                      </div>
                    </div>
                  </li>
                ))
              : null}
          </ul>
          {isSubUser && (
            <ul className="mt-2 pt-2 space-y-3 border-t-2">
              {loading ? (
                <div className="flex flex-col items-center justify-center my-20">
                  <i className="fa-solid fa-spinner fa-spin text-gray-400 text-5xl"></i>
                  <p className="text-gray-400 mt-4">Loading Parent...</p>
                </div>
              ) : parentUser ? (
                <li
                  key={parentUser._id}
                  onClick={() =>
                    setActiveChat({ type: "parentUser", chat: parentUser })
                  }
                  className="flex items-center justify-between p-2 bg-white rounded-lg hover:bg-[#E9EAEB]"
                >
                  <div className="flex items-center">
                    <div className="w-10 h-10 bg-[#F5F5F5] border border-[#E9EAEB] flex items-center justify-center rounded-full">
                      <span className="font-semibold text-xl text-[#535862]">
                        {parentUser.name.charAt(0)}
                      </span>
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-black">
                        {parentUser.name}
                      </p>
                    </div>
                  </div>
                </li>
              ) : null}
            </ul>
          )}
        </div>
        {/* Right Sidebar: Chat */}
        <div className="flex-1 flex flex-col h-full bg-white border-s">
          {activeChat ? (
            <>
              {/* Chat Header */}
              <div className="flex items-center justify-between p-4 bg-white border-b">
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-[#E9EAEB] border border-[#E9EAEB] flex items-center justify-center rounded-full">
                    <span className="font-semibold text-xl text-[#535862]">
                      {activeChat?.chat?.name
                        ? activeChat.chat.name.charAt(0)
                        : activeChat.chat.label.charAt(0)}
                    </span>
                  </div>
                  <div className="ml-3">
                    <h6 className="font-semibold text-lg text-black">
                      {activeChat.type === "parentUser"
                        ? activeChat.chat.name
                        : activeChat.type === "group"
                        ? activeChat.chat.name
                        : activeChat.chat.label}
                    </h6>
                  </div>
                </div>
              </div>
              {/* Chat Messages */}
              <div
                className="flex-1 overflow-y-auto p-4"
                style={{
                  maxHeight: "calc(100vh - 190px)",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <ul className="space-y-4">
                  {messages
                    .slice()
                    .sort(
                      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
                    )
                    .map((msg, index) => {
                      const msgDate = new Date(
                        msg.createdAt
                      ).toLocaleDateString();
                      const isToday =
                        msgDate === new Date().toLocaleDateString();

                      const showDate =
                        index === 0 ||
                        new Date(
                          messages[index - 1].createdAt
                        ).toLocaleDateString() !== msgDate;

                      return (
                        <li key={index}>
                          {showDate && (
                            <div className="flex items-center my-2">
                              <div className="flex-grow border-t border-gray-300"></div>
                              <span className="mx-2 text-gray-500 ">
                                {isToday ? "Today" : msgDate}
                              </span>
                              <div className="flex-grow border-t border-gray-300"></div>
                            </div>
                          )}
                          <div
                            className={`flex ${
                              msg.senderId === loggedInUserId
                                ? "justify-end"
                                : "justify-start"
                            }`}
                          >
                            <div
                              className={`bg-${
                                msg.senderId === loggedInUserId
                                  ? "text-end"
                                  : " text-start"
                              } ml-3`}
                            >
                              <div className="flex gap-2">
                                {msg.senderId !== loggedInUserId && (
                                  <div className="w-10 h-10 bg-[#E9EAEB] mt-1 border border-[#E9EAEB] flex items-center justify-center rounded-full">
                                    <span className="font-semibold text-xl text-[#535862]">
                                      {msg.senderName.charAt(0)}
                                    </span>
                                  </div>
                                )}
                                <div>
                                  <div className="flex justify-between mb-1">
                                    <div className="text-xs font-semibold text-[#535862]">
                                      {msg.senderId === loggedInUserId
                                        ? "You"
                                        : msg.senderName}
                                    </div>
                                    <div className="flex items-end space-x-2 ml-3">
                                      <span className="text-xs  text-[#535862]">
                                        {new Date(
                                          msg.createdAt
                                        ).toLocaleTimeString()}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className={`bg-${
                                      msg.senderId === loggedInUserId
                                        ? "themeColor text-white text-end"
                                        : "[#F5F5F5] text-[#181D27] text-start"
                                    } p-2 rounded-lg max-w-xs break-words`}
                                  >
                                    {msg.msg}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  <div ref={messagesEndRef}></div>
                </ul>
              </div>
              {/* Message Input */}
              <div className="p-4 bg-white">
                <div className="flex items-center">
                  <div className="relative w-full">
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400"
                      onClick={handleSendMessage}
                    >
                      <img src="../icons/SendMsg.svg" alt="sendIcon" />
                    </span>
                    <input
                      type="text"
                      value={currentMessage}
                      onChange={(e) => setCurrentMessage(e.target.value)}
                      className="pl-5 pr-4 py-5 w-full bg-white border rounded-lg text-sm focus:outline-none focus:ring focus:ring-themeColor3"
                      placeholder="Write a message..."
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex-1 flex flex-col h-full bg-gray-100 border-s relative">
              <div
                style={{
                  background: "rgba(228, 228, 228, 0.3",
                  backdropFilter: "blur(5px)",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 1,
                }}
              />
              <div className="flex items-center justify-between p-4 bg-white border-b">
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-themeColor3 text-gray-800 flex items-center justify-center rounded-full">
                    <span className="font-semibold text-xl">U</span>
                  </div>
                  <div className="ml-3">
                    <h6 className="font-semibold text-gray-800 text-2xl">
                      User
                    </h6>
                  </div>
                </div>
              </div>
              <div className="flex-1 overflow-y-auto p-4">
                <ul className="space-y-4">
                  <li className="flex justify-end">
                    <div className="flex items-end space-x-2">
                      <span className="text-xs text-gray-400">
                        10:10 AM, Today
                      </span>
                    </div>
                    <div className="bg-themeColor3 text-gray-800 p-3 rounded-lg max-w-xs ml-3">
                      Hi Aiden, how are you? How is the project coming along?
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-gray-200 text-white p-3 rounded-lg max-w-xs ml-3">
                      Are we meeting today?
                    </div>
                    <div className="flex items-end space-x-2 ml-3">
                      <span className="text-xs text-gray-400">
                        10:12 AM, Today
                      </span>
                    </div>
                  </li>
                  <li className="flex">
                    <div className="bg-gray-200 text-white p-3 rounded-lg max-w-xs ml-3">
                      Project has been already finished and I have results to
                      show you.
                    </div>
                    <div className="flex items-end space-x-2 ml-3">
                      <span className="text-xs text-gray-400">
                        10:15 AM, Today
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="p-4 bg-gray-100">
                <div className="flex items-center">
                  <i className="fa-solid fa-plus text-xl mr-3 text-themeColor cursor-pointer"></i>
                  <div className="relative w-full">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                      <i className="fa-solid fa-paper-plane"></i>
                    </span>
                    <input
                      type="text"
                      className="pl-10 pr-4 py-2 w-full bg-white border rounded-lg text-sm focus:outline-none focus:ring focus:ring-themeColor3"
                      placeholder="Enter text here..."
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-lg mx-auto p-2 bg-white rounded-lg shadow-lg z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="flex items-center justify-center ">
          <div className="bg-white rounded-lg w-full">
            <h4 className="text-lg font-bold mb-4 pb-3 mx-2 mt-3 border-b border-gray-300 text-black text-left">
              Add Group
            </h4>
            <div className="px-4">
              <div className="relative mb-4">
                <label className="font-semibold">Group Name</label>
                <input
                  type="text"
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  className="mt-1 mb-2 block w-full px-3 py-1 border border-gray-300 rounded text-gray-400 shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                  placeholder="Enter Group Name"
                />
              </div>
              <div className="relative mb-4">
                <label className="font-semibold">Members</label>
                <Select
                  options={filteredSubUsers}
                  value={selectedMembers.map((member) => member.value)}
                  onChange={handleMemberChange}
                  mode="multiple"
                  styles={customSelectStyles}
                  className="block w-full text-sm focus:outline-none focus:ring-0 focus:border-themeColor text-left"
                  placeholder="Select Project Members"
                  required
                />
              </div>
            </div>
            <div
              className="flex justify-end p-2 mt-4 gap-2"
              style={{
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
            >
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveGroup}
                className="bg-themeGradient text-white py-2 px-4 rounded-lg hover:bg-lightThemeColor"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Chat;
