import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import SiderProjectList from './SiderProjectList';
import LoggedInUser from './LoggedInUser';
import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Sidebar = ({ setActivePage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState('');
  const [isSubUser, setIsSubUser] = useState(true);
  const [isFromAuthProfile, setIsFromAuthProfile] = useState(false);

  useEffect(() => {
    const path = location.pathname.replace('/', '') || '/';
    setActivePage(path);
  }, [location, setActivePage]);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(`${API_BASE_URL}/auth/profile`, config);
      setUserRole(userProfile.role || 'Admin');
      setIsSubUser(false);
      setIsFromAuthProfile(true);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(`${API_BASE_URL}/subUsers/subUserProfile`, config);
          setUserRole(subUserProfile.role || '');
          setIsSubUser(true);
        } catch (subUserError) {
          toast.error('Failed to fetch sub-user data');
          console.error(subUserError);
        }
      } else {
        toast.error('Failed to fetch user profile');
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const getIcon = (path, icon, activeIcon) => {
    return location.pathname === path ? activeIcon : icon;
  };

  return (
    <div className="bg-[#EDF1F1] h-full w-[250px] fixed font-customFont">
      <div className="flex items-center justify-start ms-3 mt-3">
        <img src="/logo.png" className="w-[40px]" alt="Logo" />
        <p className="text-black font-bold text-2xl ml-1">Expense</p>
      </div>
      
      <div className="mt-2 ms-3 pb-2 border-b-2 border-[#D5D7DA]">
    
      { (isSubUser || userRole === 'View') || userRole === "Admin" || userRole === "All" ? (
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/dashboard' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/dashboard')}
        >
          <img src={getIcon('/Panel/dashboard', "/icons/Dashboard.svg", "/icons/DashboardActive.svg")} alt="Dashboard icon" className="mr-3 w-5 h-5" />
          <span className="flex-1">Dashboard</span>
        </div>
        
      ) : null }
      { (isSubUser || userRole === 'View') || userRole === "Edit" || userRole === "Admin" || userRole === "All" ? (
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/expenseList' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/expenseList')}
        >
          <img src={getIcon('/Panel/expenseList', "/icons/Expenses.svg", "/icons/ExpensesActive.svg")} alt="Expense List icon" className="mr-3 w-5 h-5" />
          <span className="flex-1">Expenses</span>
        </div>
      ) : null }
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/chats' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/chats')}
        >
          <img src={getIcon('/Panel/chats', "/icons/Chat.svg", "/icons/ChatActive.svg")} alt="Chat icon" className="mr-3 w-5 h-5" />
          <span className="flex-1">Chat</span>
        </div>
        { userRole === "Admin" || userRole === "All" ? (
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/projects' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/projects')}
        >
          <img src={getIcon('/Panel/projects', "/icons/Projects.svg", "/icons/ProjectsActive.svg")} alt="Project icon" className="mr-3 w-5 h-5" />
          <span className="flex-1">Projects</span>
        </div>
        ) : null }
        {isFromAuthProfile ? (
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/team' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/team')}
        >
          <img src={getIcon('/Panel/team', "/icons/Team.svg", "/icons/TeamActive.svg")} alt="Team icon" className="mr-3 w-5 h-5" />
          <span className="flex-1">Team</span>
        </div>
        ) : null }
        { userRole === "Admin" || userRole === "All" ? (
        <div
          className={`flex items-center p-2 pl-2 cursor-pointer ${
            location.pathname === '/Panel/invoice' ? 'bg-white text-black rounded-md shadow-sm' : 'text-black'
          }`}
          onClick={() => navigate('/Panel/invoice')}
        >
          <img src={getIcon('/Panel/invoice', "/icons/InvoiceActive.svg", "/icons/InvoiceActive.svg")} alt="Invoice icon" className="mr-3 w-5 h-4" />
          <span className="flex-1">Invoice</span>
        </div>
        ) : null }
      </div>

      <SiderProjectList/>

      <div
        className="absolute bottom-0 left-0 w-full p-2 pl-3 flex items-center cursor-pointer text-gray-700"
      >
        <LoggedInUser />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  setActivePage: PropTypes.func.isRequired,
};

export default Sidebar;
