import React, { useEffect, useState } from "react";
import { message, Popover } from "antd";
import axios from "axios";
import LoggedInUserPopup from "./LoggedInUserPopup";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const LoggedInUser = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error("Not authenticated");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const userProfileResponse = await axios
        .get(`${API_BASE_URL}/auth/profile`, config)
        .catch(async (error) => {
          if (error.response && error.response.status === 404) {
            return await axios.get(
              `${API_BASE_URL}/subusers/subuserprofile`,
              config
            );
          } else {
            throw error;
          }
        });

      if (userProfileResponse) {
        setUserData(userProfileResponse.data);
      }
    } catch (error) {
      message.error("Failed to fetch user data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  return (
    <div className="w-full">
      <Popover
        content={<LoggedInUserPopup />}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={handlePopoverVisibleChange}
        placement="bottomLeft"
      >
        <div className="border-2 border-[#E9EAEB] rounded-xl w-full bg-white p-2 flex justify-between shadow-sm font-customFont">
          <div className="flex items-center">
            <div className="w-10 h-10 bg-[#F5F5F5] border border-[#E9EAEB] flex items-center justify-center rounded-full">
              <span className="font-semibold text-xl text-[#535862] font-bold">
                {userData ? userData.name.charAt(0).toUpperCase() : "U"}
              </span>
            </div>
            <div className="ml-3">
              <p className="font-semibold text-black">
                {userData ? userData.name : "User Name"}
              </p>
            </div>
          </div>
          <div className="items-center flex">
            {popoverVisible ? (
              <i className="fa-solid fa-angle-up"></i>
            ) : (
              <i className="fa-solid fa-angle-down"></i>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default LoggedInUser;
