import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import { useParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAllMonths = () => [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const processExpenses = (expenses) => {
  const months = getAllMonths();
  const currentYear = new Date().getFullYear();
  const monthlyData = {};

  months.forEach((month) => {
    monthlyData[month] = { income: 0, expense: 0, profit: 0 };
  });

  expenses.forEach((expense) => {
    const expenseDate = new Date(expense.date);
    const year = expenseDate.getFullYear();
    const month = expenseDate.toLocaleString("default", { month: "short" });

    if (year === currentYear) {
      const { priceUSD, type } = expense;
      const price = Number(priceUSD) || 0;

      if (type === "Income") {
        monthlyData[month].income += price;
      } else if (type === "Expense") {
        monthlyData[month].expense += price;
      }
      monthlyData[month].profit =
        monthlyData[month].income - monthlyData[month].expense;
    }
  });

  return months.map((month) => ({
    month,
    income: monthlyData[month].income,
    expense: monthlyData[month].expense,
    profit: Math.max(monthlyData[month].profit, 0),
  }));
};

const MonthlyExpense = ({ project }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expenseData, setExpenseData] = useState([]);
  const [showIncome, setShowIncome] = useState(true);
  const [showExpense, setShowExpense] = useState(true);
  const [showProfit, setShowProfit] = useState(true);

  const fetchExpenses = async () => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("token");

    if (!token) {
      setExpenseData(
        getAllMonths().map((month) => ({
          month,
          income: 0,
          expense: 0,
          profit: 0,
        }))
      );
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/expenses/getAllExpenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch expenses");
      const expenseData = await response.json();

      const filteredExpenses = project
        ? expenseData.filter((exp) => exp.project === project)
        : expenseData;

      setExpenseData(processExpenses(filteredExpenses));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [project]);

  const handleIncomeChange = () => {
    setShowIncome((prev) => !prev);
  };

  const handleExpenseChange = () => {
    setShowExpense((prev) => !prev);
  };

  const handleProfitChange = () => {
    setShowProfit((prev) => !prev);
  };

  if (loading) return;
  <div>
    <div class="spinner"></div>
  </div>;
  if (error) return <p>Error: {error}</p>;

  const showGraph = showIncome || showExpense || showProfit;

  return (
    <div className="rounded-lg bg-white p-4 font-customFont">
      <div className="flex">
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showIncome}
            onChange={handleIncomeChange}
            className="custom-radioIncome me-1"
          />
          <span className="text-black">Total Income</span>
        </label>
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showExpense}
            onChange={handleExpenseChange}
            className="custom-radioExpense me-1"
          />
          <span className="text-black">Total Expense</span>
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={showProfit}
            onChange={handleProfitChange}
            className="custom-radioNetIncome me-1"
          />
          <span className="text-black">Total Net Income</span>
        </label>
      </div>

      {expenseData.length === 0 ? (
        <p>No expenses available for the selected duration.</p>
      ) : (
        <>
          {!showGraph ? (
            <p>Please select at least one metric to display.</p>
          ) : (
            <BarChart
              dataset={expenseData}
              xAxis={[{ scaleType: "band", dataKey: "month" }]}
              yAxis={[{ scaleType: "linear", dataKey: "income" }]}
              series={[
                showIncome
                  ? {
                      dataKey: "income",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#4CAF50",
                    }
                  : null,
                showExpense
                  ? {
                      dataKey: "expense",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#FF5733",
                    }
                  : null,
                showProfit
                  ? {
                      dataKey: "profit",
                      valueFormatter: (value) => `$${value.toFixed(2)}`,
                      color: "#3F51B5",
                    }
                  : null,
              ].filter(Boolean)}
              layout="vertical"
              margin={{ right: 30, left: 60 }}
              height={350}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MonthlyExpense;
