import React, { useState, useEffect } from "react";
import "../App.css";
import axios from "axios";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "PKR":
      return "Rs";
    case "SAR":
      return "﷼";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    default:
      return currency;
  }
};

const ProjectCards = () => {
  const [projects, setProjects] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userProject, setUserProject] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    const fetchExpenses = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/expenses/getAllExpenses`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch expenses");
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          const projectMap = data.reduce((acc, expense) => {
            const { project, price, type, projectCurrency } = expense;

            if (!acc[project]) {
              acc[project] = {
                income: 0,
                expense: 0,
                currency: projectCurrency,
              };
            }

            if (type === "Income") {
              acc[project].income += price;
            } else if (type === "Expense") {
              acc[project].expense += price;
            }

            return acc;
          }, {});

          const projects = Object.entries(projectMap).map(
            ([project, { income, expense, currency }]) => ({
              project,
              income,
              expense,
              currency,
            })
          );

          setProjects(projects);
        } else {
          throw new Error("Expenses data is not an array");
        }
      } catch (error) {
        console.error("Error fetching expenses:", error);
      }
    };

    const fetchUserProfile = async () => {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const { data: userProfile } = await axios.get(
          `${API_BASE_URL}/auth/profile`,
          config
        );
        setUserRole(userProfile.role || "Admin");
        setUserProject("");
        setIsSubUser(false);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          try {
            const { data: subUserProfile } = await axios.get(
              `${API_BASE_URL}/subUsers/subUserProfile`,
              config
            );
            setUserRole(subUserProfile.role || "");
            setIsSubUser(true);
            setUserProject(subUserProfile.project || "");
          } catch (subUserError) {
            console.error(subUserError);
          }
        } else {
          console.error(error);
        }
      }
    };

    fetchUserProfile();
    fetchExpenses();
  }, []);

  useEffect(() => {
    if (projects.length > 0) {
      if (userRole === "Admin") {
        setFilteredProjects(projects);
      } else if (isSubUser) {
        const relevantProjects = projects.filter(
          (pro) => pro.project === userProject
        );
        setFilteredProjects(relevantProjects);
      }
    }
  }, [projects, userRole, isSubUser]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {filteredProjects.map((pro, index) => (
        <div
          key={index}
          className="bg-gray-50 rounded-lg border border-gray-200 shadow-sm overflow-hidden"
        >
          <div className="px-4 py-2">
            <h3 className="text-lg font-semibold text-black">{pro.project}</h3>
            <div className="flex justify-between mt-5">
              <div>
                <p className="text-[#535862]">Income</p>
                <p className="font-bold text-[#006B31]">
                  {getCurrencySymbol(pro.currency)}
                  {pro.income.toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-[#535862]">Expense</p>
                <p className="font-bold text-[#B51D1D]">
                  {getCurrencySymbol(pro.currency)}
                  {pro.expense.toFixed(2)}
                </p>
              </div>
              <div>
                <p className="text-[#535862]">Net Income</p>
                <p className="font-bold text-[#000B33]">
                  {getCurrencySymbol(pro.currency)}
                  {pro.income - pro.expense.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectCards;
