import React, { useState, useEffect } from "react";
import "../App.css";
import { toast } from "react-toastify";
import ProjectCards from "../components/ProjectCards";
import axios from "axios";
import WeeklyExpense from "../components/WeeklyExpense";
import YearlyExpense from "../components/YearlyExpense";
import MonthlyExpense from "../components/MonthlyExpense";
import { Link } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getAllMonths = () => [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const getCurrentWeekDays = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(today);
    date.setDate(today.getDate() + startOfWeek + i);
    return date.toLocaleString("default", { weekday: "short" });
  });
};
const getLast6Years = () => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 6 }, (_, index) => currentYear - index).reverse();
};
const processExpenses = (expenses, type) => {
  if (type === "monthly") {
    const months = getAllMonths();
    const currentYear = new Date().getFullYear();
    const monthlyData = {};

    months.forEach((month) => {
      monthlyData[month] = { income: 0, expense: 0, profit: 0 };
    });

    expenses.forEach((expense) => {
      const expenseDate = new Date(expense.date);
      const year = expenseDate.getFullYear();
      const month = expenseDate.toLocaleString("default", { month: "short" });

      if (year === currentYear) {
        const { priceUSD, type } = expense;
        if (type === "Income") {
          monthlyData[month].income += priceUSD;
        } else {
          monthlyData[month].expense += priceUSD;
        }
        monthlyData[month].profit =
          monthlyData[month].income - monthlyData[month].expense;
      }
    });

    return months.map((month) => ({
      month,
      income: monthlyData[month].income,
      expense: monthlyData[month].expense,
      profit: monthlyData[month].profit > 0 ? monthlyData[month].profit : null,
    }));
  }

  if (type === "weekly") {
    const daysOfWeek = getCurrentWeekDays();
    const weeklyData = {};

    daysOfWeek.forEach((day) => {
      weeklyData[day] = { income: 0, expense: 0, profit: 0 };
    });

    expenses.forEach((expense) => {
      const expenseDate = new Date(expense.date);
      const today = new Date();
      const dayOfWeek = today.getDay();
      const startOfWeek = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
      const startOfWeekDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + startOfWeek
      );

      if (expenseDate >= startOfWeekDate && expenseDate <= today) {
        const day = expenseDate.toLocaleString("default", { weekday: "short" });
        const { priceUSD, type } = expense;
        if (type === "Income") {
          weeklyData[day].income += priceUSD;
        } else {
          weeklyData[day].expense += priceUSD;
        }
        weeklyData[day].profit =
          weeklyData[day].income - weeklyData[day].expense;
      }
    });

    return daysOfWeek.map((day) => ({
      day,
      income: weeklyData[day].income,
      expense: weeklyData[day].expense,
      profit: weeklyData[day].profit > 0 ? weeklyData[day].profit : null,
    }));
  }

  if (type === "yearly") {
    const years = getLast6Years();
    const yearlyData = {};

    years.forEach((year) => {
      yearlyData[year] = { income: 0, expense: 0, profit: 0 };
    });

    expenses.forEach((expense) => {
      const year = new Date(expense.date).getFullYear();
      const { priceUSD, type } = expense;
      if (yearlyData[year] !== undefined) {
        if (type === "Income") {
          yearlyData[year].income += priceUSD;
        } else {
          yearlyData[year].expense += priceUSD;
        }
        yearlyData[year].profit =
          yearlyData[year].income - yearlyData[year].expense;
      }
    });

    return years.map((year) => ({
      year,
      income: yearlyData[year].income,
      expense: yearlyData[year].expense,
      profit: yearlyData[year].profit > 0 ? yearlyData[year].profit : 0,
    }));
  }
};

const Dashboard = () => {
  const [duration, setDuration] = useState("Last 12 Months");
  const [allExpensesData, setAllExpensesData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [project, setProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [userProject, setUserProject] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);

  const filterExpensesByDuration = (expenses, duration) => {
    const today = new Date();
    const filteredExpenses = expenses.filter((expense) => {
      const expenseDate = new Date(expense.date);
      switch (duration) {
        case "Last 7 Days":
          return expenseDate >= new Date(today.setDate(today.getDate() - 7));
        case "Last 30 Days":
          return expenseDate >= new Date(today.setDate(today.getDate() - 30));
        case "Last 12 Months":
          return expenseDate.getFullYear() === today.getFullYear();
        case "Last 6 Years":
          return expenseDate.getFullYear() >= today.getFullYear() - 6;
        default:
          return true;
      }
    });
    return filteredExpenses;
  };

  const fetchExpenses = async (selectedProject) => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("token");

    if (!token) {
      setAllExpensesData([]);
      setMonthlyData(getAllMonths().map((month) => ({ month, expenses: 0 })));
      setWeeklyData(getCurrentWeekDays().map((day) => ({ day, expenses: 0 })));
      setYearlyData(getLast6Years().map((year) => ({ year, expenses: 0 })));
      setLoading(false);
      return;
    }

    let projectToFetch;

    if (isSubUser && userRole === "Admin") {
      projectToFetch = selectedProject === "All" ? null : selectedProject;
    } else if (!isSubUser) {
      projectToFetch = selectedProject === "All" ? null : selectedProject;
    } else {
      projectToFetch = userProject;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/expenses/getAllExpenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch expenses");
      const expenseData = await response.json();

      const filteredExpenses = projectToFetch
        ? expenseData.filter((exp) => exp.project === projectToFetch)
        : expenseData;

      const expensesByDuration = filterExpensesByDuration(
        filteredExpenses,
        duration
      );

      setMonthlyData(processExpenses(expensesByDuration, "monthly"));
      setWeeklyData(processExpenses(expensesByDuration, "weekly"));
      setYearlyData(processExpenses(expensesByDuration, "yearly"));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotal = (data, key) =>
    data.reduce((acc, item) => acc + (item[key] || 0), 0);

  const selectedData =
    duration === "Last 7 Days"
      ? weeklyData
      : duration === "Last 30 Days"
      ? weeklyData
      : duration === "Last 12 Months"
      ? monthlyData
      : yearlyData;

  const totalIncome = calculateTotal(selectedData, "income");
  const totalExpenses = calculateTotal(selectedData, "expense");

  useEffect(() => {
    fetchExpenses(project);
  }, [project, duration]);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserProject("All");
      setIsSubUser(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserProject(subUserProfile.project || "");
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);

          if (subUserProfile.role === "admin") {
            setUserProject("All");
          }
        } catch (subUserError) {
          console.error(subUserError);
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }
        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        toast.error("Failed to load projects");
      }
    };

    fetchProjects();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    fetchExpenses();
  }, [userProject, userRole, isSubUser]);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!isSubUser || userRole === "Admin") {
      setProject("All");
    } else {
      setProject(userProject);
    }
  }, [isSubUser, userRole, userProject]);

  const handleProjectChange = (value) => {
    setProject(value);
    fetchExpenses(value);
  };

  const handleRefresh = () => {
    if (!isSubUser || userRole === "Admin") {
      setProject("All");
    } else {
      setProject(userProject);
    }
    fetchExpenses();
  };
  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (!isSubUser || userRole === "Admin") {
      setProject();
    } else {
      setProject(userProject);
    }
  }, [isSubUser, userRole, userProject]);

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div class="spinner"></div>
      </div>
    );
  if (error) return <p>Error: {error}</p>;
  return (
    <div className="flex flex-col gap-2 h-full bg-white rounded-xl py-3 px-4 border border-2 border-[#E9EAEB] space-y-2">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <div
          style={{ fontFamily: "Roboto, sans-serif" }}
          className="mb-4 md:mb-0"
        >
          <h1 className="font-bold text-lg">Dashboard</h1>
          <p className="text-xs text-gray-500 font-semibold">
            View and manage your expenses
          </p>
        </div>

        <div className="flex gap-2 items-center">
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md"
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          {(!isSubUser ||
            (isSubUser && (userRole === "Edit" || userRole === "Admin"))) && (
            <Link to="/panel/addExpense">
              <button className="bg-themeGradient text-white px-3 sm:px-4 py-1 rounded-md flex items-center">
                <i className="fa-solid fa-plus text-lg"></i>
                <span className="ms-2 text-sm sm:text-base">
                  New Transaction
                </span>
              </button>
            </Link>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 w-full text-center mb-4 font-customFont">
        <div className="border border-[#AFF0CC] bg-[#EDFFF5] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#006B31] text-md">Total Income</p>
          <p className="text-[#002410] text-3xl font-bold mt-1">
            $ <span>{Math.floor(totalIncome).toLocaleString()}</span>
            <span className="text-[#55AA55] text-xl">
              .{totalIncome.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="relative border border-[#FFE5E3] bg-[#FFF5EF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#B51D1D] text-md">Total Expense</p>
          <p className="text-[#7C1A1D] text-3xl font-bold mt-1">
            $ <span>{Math.floor(totalExpenses).toLocaleString()}</span>
            <span className="text-[#D65555] text-xl">
              .{totalExpenses.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="border border-[#CCD7FF] bg-[#E5EBFF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#002199] text-md">Total Net Income</p>
          <p className="text-[#000B33] text-3xl font-bold mt-1">
            ${" "}
            <span>
              {Math.floor(totalIncome - totalExpenses).toLocaleString()}
            </span>
            <span className="text-[#555588] text-xl">
              .{(totalIncome - totalExpenses).toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
      </div>

      <div className="w-full rounded-lg border border-[#E5E7EB] shadow-sm p-3">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 md:gap-0">
          <div>
            <p className="font-semibold font-customFont">Overview</p>
          </div>

          <div className="flex flex-col sm:flex-row gap-2 w-full md:w-auto font-customFont">
            <Select
              defaultValue="All"
              onChange={handleProjectChange}
              value={project}
              required
              className="w-full sm:w-auto lg:w-60 ring-1 ring-blue-400 rounded-md"
            >
              {(isSubUser && userRole === "Admin") || !isSubUser ? (
                <>
                  <Option value="All">All Projects</Option>
                  {projects.map((pro) => (
                    <Option key={pro._id} value={pro.project}>
                      {pro.project}
                    </Option>
                  ))}
                </>
              ) : (
                <Option value={userProject}>{userProject}</Option>
              )}
            </Select>

            <Select
              defaultValue="Last 12 Months"
              required
              value={duration}
              onChange={(value) => setDuration(value)}
              onBlur={() => {
                if (!duration) setDuration("");
              }}
              className={`w-full sm:w-auto lg:w-60 ${
                duration ? "ring-1 ring-blue-400 rounded-md" : ""
              }`}
            >
              <Option value="">Select Duration</Option>
              {(isSubUser && userRole === "Admin") || !isSubUser ? (
                <>
                  <Option value="Last 7 Days">Last 7 Days</Option>
                  <Option value="Last 12 Months">Last 12 Months</Option>
                  <Option value="Last 6 Years">Last 6 Years</Option>
                </>
              ) : (
                <Option value={userProject}>{userProject}</Option>
              )}
            </Select>
          </div>
        </div>

        <div>
          {duration === "Last 6 Years" && <YearlyExpense project={project} />}
          {duration === "Last 12 Months" && (
            <MonthlyExpense project={project} />
          )}
          {duration === "Last 7 Days" && <WeeklyExpense project={project} />}
        </div>
      </div>
      <div>
        <ProjectCards />
      </div>
    </div>
  );
};

export default Dashboard;
