import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Popconfirm, message, Select } from "antd";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { Link } from "react-router-dom";
import ImportBulkExpenses from "../components/ImportBulkExpenses";
const { Option } = Select;

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "PKR":
      return "Rs";
    case "SAR":
      return "﷼";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    default:
      return currency;
  }
};

const ExpenseList = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projects, setProjects] = useState([]);
  const [filterType, setFilterType] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editExpense, setEditExpense] = useState(null);
  const [userProject, setUserProject] = useState("");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);
  const tableRef = useRef(null);
  const [showPriceUSD, setShowPriceUSD] = useState(true);
  const [filterProject, setFilterProject] = useState("");

  const fetchExpenses = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${API_BASE_URL}/expenses/getAllExpenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch expenses");
      const expenseData = await response.json();
      const sortedExpenses = expenseData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      const expensesToSet =
        userProject === "All"
          ? sortedExpenses
          : sortedExpenses.filter((expense) => expense.project === userProject);

      setExpenses(expensesToSet);
      setFilteredExpenses(expensesToSet);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/projects/getProjectList`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch projects");
      const data = await response.json();
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        throw new Error("Projects data is not an array");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to load projects");
    }
  };

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserProject("All");
      setIsSubUser(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserProject(subUserProfile.project || "");
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);
        } catch (subUserError) {
          message.error("Failed to fetch sub-user data");
          console.error(subUserError);
        }
      } else {
        message.error("Failed to fetch user profile");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchExpenses();
    fetchProjects();
  }, []);

  const handleStartDateChange = (event) => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
    filterExpenses(selectedDate, endDate, filterProject, filterType);
  };

  const handleEndDateChange = (event) => {
    const selectedDate = event.target.value;
    setEndDate(selectedDate);
    filterExpenses(startDate, selectedDate, filterProject, filterType);
  };

  const handleFilterProjectChange = (value) => {
    if (value === "All") {
      handleRefresh();
    } else {
      setFilterProject(value);
      filterExpenses(startDate, endDate, value);
    }
  };

  const handleFilterTypeChange = (value) => {
    setFilterType(value);
    filterExpenses(startDate, endDate, filterProject, value);
  };

  const togglePrice = () => setShowPriceUSD(!showPriceUSD);

  const filterExpenses = (start, end, project, type) => {
    let filtered = expenses;
    if (start) {
      const startDateObj = new Date(start);
      filtered = filtered.filter(
        (expense) => new Date(expense.date) >= startDateObj
      );
    }

    if (end) {
      const endDateObj = new Date(end);
      filtered = filtered.filter(
        (expense) => new Date(expense.date) <= endDateObj
      );
    }

    if (project) {
      filtered = filtered.filter((expense) => expense.project === project);
    }

    if (type) {
      filtered = filtered.filter((expense) => expense.type === type);
    }

    if (userProject !== "All") {
      filtered = filtered.filter((expense) => expense.project === userProject);
    }

    setFilteredExpenses(filtered);
  };

  useEffect(() => {
    if (userProject !== "") {
      fetchExpenses();
    }
  }, [userProject]);

  const handleRefresh = () => {
    setStartDate("");
    setEndDate("");
    setFilterProject("");
    fetchExpenses();
  };

  const totalIncomeUSD = filteredExpenses.reduce((acc, expense) => {
    return expense.type === "Income" ? acc + expense.priceUSD : acc;
  }, 0);

  const totalExpensesUSD = filteredExpenses.reduce((acc, expense) => {
    return expense.type === "Expense" ? acc + expense.priceUSD : acc;
  }, 0);

  const totalIncome = filteredExpenses.reduce((acc, expense) => {
    return expense.type === "Income" ? acc + expense.price : acc;
  }, 0);

  const totalExpenses = filteredExpenses.reduce((acc, expense) => {
    return expense.type === "Expense" ? acc + expense.price : acc;
  }, 0);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/expenses/deleteExpense/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Delete failed with status ${response.status}: ${response.statusText}`
        );
        throw new Error("Failed to delete expense");
      }
      toast.success("Expense deleted successfully");
      fetchExpenses();
    } catch (error) {
      console.error("Error deleting expense:", error);
      toast.error("Failed to delete expense");
    }
  };

  const handleUpdate = async (updatedExpense) => {
    const token = localStorage.getItem("token");
    const expenseId = updatedExpense._id;

    const newPriceUSD = updatedExpense.price / updatedExpense.conversionRate;

    try {
      const response = await fetch(
        `${API_BASE_URL}/expenses/updateExpense/${expenseId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...updatedExpense,
            priceUSD: newPriceUSD,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update expense");
      }

      const result = await response.json();
      console.log("Expense updated successfully:", result);
      toast.success("Expense updated successfully");
      fetchExpenses();
      closeModal();
    } catch (error) {
      console.error("Error updating expense:", error);
      toast.error("Error updating expense");
    }
  };

  const openEditModal = (expense) => {
    setEditExpense(expense);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditExpense(null);
  };

  const handleDownloadPDF = async () => {
    const input = tableRef.current;

    if (!input) return;

    const actionHeader = input.querySelector("th:nth-child(7)");
    const actionCells = input.querySelectorAll("td:nth-child(7)");

    if (actionHeader) actionHeader.style.display = "none";
    actionCells.forEach((cell) => (cell.style.display = "none"));

    await new Promise((resolve) => setTimeout(resolve, 100));

    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgWidth = 190;
    const pageHeight = pdf.internal.pageSize.height;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    let position = 10;

    pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
    heightLeft -= pageHeight - position;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight + 10;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    if (actionHeader) actionHeader.style.display = "";
    actionCells.forEach((cell) => (cell.style.display = ""));

    pdf.save("ExpenseList.pdf");
  };

  return (
    <div className="rounded-xl bg-white h-full py-3 px-4 border border-2 border-[#E9EAEB]  font-customFont">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Expenses</h1>
          <p className="text-xs text-gray-500 font-semibold">
            View and manage your expenses
          </p>
        </div>
        <div className="flex gap-3 items-center mt-2 md:mt-0">
          {" "}
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md mb-4 md:mb-0"
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          <button
            onClick={handleDownloadPDF}
            className="text-black border border-[#DCE3E3] px-2 py-2 rounded-md mb-4 md:mb-0 flex items-center"
          >
            <img src="../icons/Download.svg" className="w-5" alt="Download" />
            <span className="ms-2">Download PDF</span>
          </button>
          <div>
            <ImportBulkExpenses />
          </div>
          {(!isSubUser ||
            (isSubUser && (userRole === "Edit" || userRole === "Admin"))) && (
            <Link to="/panel/addExpense">
              <button className="bg-themeGradient text-white px-2 py-1 rounded-md mb-4 md:mb-0 flex items-center">
                <i className="fa-solid fa-plus text-lg"></i>
                <span className="ms-2">New Transaction</span>
              </button>
            </Link>
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-3 my-4">
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          {" "}
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={handleStartDateChange}
            className={`border border-gray-300 rounded-md py-1 px-2 w-full ${
              startDate ? "ring-1 ring-blue-500 rounded-md" : ""
            }`}
          />
        </div>
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          {" "}
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={handleEndDateChange}
            className={`border border-gray-300 rounded-md py-1 px-2 w-full ${
              endDate ? "ring-1 ring-blue-500 rounded-md" : ""
            }`}
          />
        </div>

        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          {" "}
          <Select
            defaultValue="All"
            id="filterType"
            value={filterType}
            onChange={handleFilterTypeChange}
            className={`${filterType ? "ring-1 ring-blue-400 rounded-md" : ""}`}
          >
            <Option value="All">All Expense</Option>
            <Option value="Income">Income</Option>
            <Option value="Expense">Expense</Option>
          </Select>
        </div>

        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          <Select
            id="filterProject"
            defaultValue={
              filterProject ||
              ((isSubUser && userRole === "Admin") || !isSubUser
                ? "All"
                : userProject)
            }
            value={
              filterProject ||
              ((isSubUser && userRole === "Admin") || !isSubUser
                ? "All"
                : userProject)
            }
            onChange={(value) => handleFilterProjectChange(value)}
            className={`ring-1 rounded-md ${
              filterProject ||
              filterProject === "All" ||
              filterProject === userProject
                ? ""
                : "ring-blue-400"
            }`}
          >
            {(isSubUser && userRole === "Admin") || !isSubUser ? (
              <>
                <Option value="All">All Projects</Option>
                {projects.map((pro) => (
                  <Option key={pro._id} value={pro.project}>
                    {pro.project}
                  </Option>
                ))}
              </>
            ) : (
              <Option value={userProject}>{userProject}</Option>
            )}
          </Select>
        </div>

        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          {" "}
          <Select
            defaultValue="Dollar"
            onChange={togglePrice}
            className={`${
              showPriceUSD ? "ring-1 ring-blue-400 rounded-md" : ""
            }`}
          >
            <Option value="Dollar">Dollar</Option>
            <Option value="Custom">Project Currency</Option>
          </Select>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-4 w-full text-center mb-4">
        <div className="border border-[#AFF0CC] bg-[#EDFFF5] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#006B31] text-md">Total Income</p>
          <p className="text-[#002410] text-3xl font-bold mt-1">
            $ <span>{Math.floor(totalIncome).toLocaleString()}</span>
            <span className="text-[#55AA55] text-xl">
              .{totalExpenses.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>

        <div className="relative border border-[#FFE5E3] bg-[#FFF5EF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#B51D1D] text-md">Total Expense</p>
          <p className="text-[#7C1A1D] text-3xl font-bold mt-1">
            $ <span>{Math.floor(totalExpenses).toLocaleString()}</span>
            <span className="text-[#D65555] text-xl">
              .{totalExpenses.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
        <div className="border border-[#CCD7FF] bg-[#E5EBFF] shadow-sm rounded-lg w-full py-4">
          <p className="text-[#002199] text-md">Total Net Income</p>
          <p className="text-[#000B33] text-3xl font-bold mt-1">
            ${" "}
            <span>
              {Math.floor(totalIncome - totalExpenses).toLocaleString()}
            </span>
            <span className="text-[#555588] text-xl">
              .{totalExpenses.toFixed(2).split(".")[1]}
            </span>
          </p>
        </div>
      </div>

      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center h-48 mt-20">
            <div class="spinner"></div>
          </div>
        ) : (
          <div className="relative">
            <div className="max-h-[500px] scrollbar-hide">
              <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
                <div className="min-w-[800px] sm:min-w-full rounded-lg">
                  <table
                    className="min-w-full divide-y divide-gray-200"
                    ref={tableRef}
                  >
                    <thead className="bg-[#E9EAEB] text-[#535862] font-semibold sticky top-0 rounded-t-lg">
                      <tr>
                        <th className="px-4 sm:px-6 py-3 text-left text-xs uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-4 sm:px-6 py-3 text-left text-xs uppercase tracking-wider">
                          Title
                        </th>
                        <th className="px-4 sm:px-6 py-3 text-left text-xs uppercase tracking-wider">
                          Project
                        </th>
                        <th className="px-4 sm:px-6 py-3 text-center text-xs uppercase tracking-wider">
                          Type
                        </th>
                        <th className="px-4 sm:px-6 py-3 text-center text-xs uppercase tracking-wider">
                          Income
                        </th>
                        <th className="px-4 sm:px-6 py-3 text-center text-xs uppercase tracking-wider">
                          Expense
                        </th>
                        {(!isSubUser ||
                          (isSubUser &&
                            (userRole === "Edit" || userRole === "Admin"))) && (
                          <th className="px-4 sm:px-6 py-3 text-left text-xs uppercase tracking-wider">
                            Action
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 text-[#535862]">
                      {filteredExpenses.length === 0 ? (
                        <tr>
                          <td colSpan="7">
                            <div className="flex flex-col items-center justify-center my-20">
                              <i className="fa-solid fa-sack-dollar text-gray-400 text-5xl"></i>
                              <p className="text-gray-400 mt-4">
                                There is no Expense
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        filteredExpenses.map((expense) => (
                          <tr key={expense._id}>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                              {new Date(expense.date).toLocaleDateString()}
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                              {expense.title}
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm flex">
                              <img
                                src="../icons/Projects.svg"
                                className="me-2"
                                alt="project icon"
                              />
                              {expense.project}
                            </td>
                            <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-center">
                              <span
                                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  expense.type === "Income"
                                    ? "bg-green-100 text-green-800"
                                    : "bg-red-100 text-red-800"
                                }`}
                              >
                                {expense.type}
                              </span>
                            </td>
                            <td
                              className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-center text-[#00B252] cursor-pointer"
                              onClick={togglePrice}
                            >
                              {expense.type === "Income"
                                ? showPriceUSD
                                  ? `$ ${(expense.priceUSD || 0).toFixed(2)}`
                                  : `${getCurrencySymbol(
                                      expense.projectCurrency
                                    )} ${(expense.price || 0).toFixed(2)}`
                                : ""}
                            </td>
                            <td
                              className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-center text-[#DB4F00] cursor-pointer"
                              onClick={togglePrice}
                            >
                              {expense.type === "Expense"
                                ? showPriceUSD
                                  ? `$ ${(expense.priceUSD || 0).toFixed(2)}`
                                  : `${getCurrencySymbol(
                                      expense.projectCurrency
                                    )} ${(expense.price || 0).toFixed(2)}`
                                : ""}
                            </td>
                            {(!isSubUser ||
                              (isSubUser &&
                                (userRole === "Edit" ||
                                  userRole === "Admin"))) && (
                              <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                                <div className="flex gap-1">
                                  <img
                                    src="../icons/Edit.svg"
                                    className="cursor-pointer"
                                    onClick={() => openEditModal(expense)}
                                    alt="edit icon"
                                  />
                                  <Popconfirm
                                    title="Are you sure you want to delete this expense?"
                                    onConfirm={() => handleDelete(expense._id)}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      src="../icons/Delete.svg"
                                      className="cursor-pointer"
                                      alt="delete icon"
                                    />
                                  </Popconfirm>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-lg mx-auto mt-20 p-6 bg-white rounded-lg shadow-lg z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-lg font-semibold mb-4 border-b pb-3">
          Edit Expense
        </h2>
        {editExpense && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const updatedExpense = {
                ...editExpense,
                title: e.target.title.value,
                price: parseFloat(e.target.price.value),
                date: e.target.date.value,
                description: e.target.description.value,
              };
              handleUpdate(updatedExpense);
            }}
            className="space-y-4"
          >
            <div>
              <label className="block font-medium">Title:</label>
              <input
                type="text"
                name="title"
                defaultValue={editExpense.title}
                required
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div>
              <label className="block font-medium">Amount:</label>
              <input
                type="number"
                name="price"
                defaultValue={editExpense.price}
                required
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div>
              <label className="block font-medium">Date:</label>
              <input
                type="date"
                name="date"
                defaultValue={
                  new Date(editExpense.date).toISOString().split("T")[0]
                }
                required
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div>
              <label className="block font-medium">Description:</label>
              <textarea
                name="description"
                defaultValue={editExpense.description}
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                type="submit"
                className="bg-themeGradient text-white px-4 py-2 rounded-md"
              >
                Update
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default ExpenseList;
