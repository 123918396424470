import React, { useState, useEffect } from "react";
import { BarChart } from "@mui/x-charts";
import { useParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const processYearlyExpenses = (expenses) => {
  const currentYear = new Date().getFullYear();
  const yearlyData = {};

  for (let year = currentYear - 5; year <= currentYear; year++) {
    yearlyData[year] = { income: 0, expense: 0, profit: 0 };
  }

  expenses.forEach((expense) => {
    const expenseDate = new Date(expense.date);
    const year = expenseDate.getFullYear();
    const { priceUSD, type } = expense;

    if (yearlyData[year]) {
      if (type === "Income") {
        yearlyData[year].income += priceUSD;
      } else {
        yearlyData[year].expense += priceUSD;
      }
      yearlyData[year].profit =
        yearlyData[year].income - yearlyData[year].expense;
    }
  });

  return Object.keys(yearlyData).map((year) => ({
    year,
    income: yearlyData[year].income,
    expense: yearlyData[year].expense,
    profit: yearlyData[year].profit > 0 ? yearlyData[year].profit : null,
  }));
};

const filterExpensesByDuration = (expenses, duration) => {
  const today = new Date();
  return expenses.filter((expense) => {
    const expenseDate = new Date(expense.date);
    switch (duration) {
      case "Last 7 Days":
        return expenseDate >= new Date(today.setDate(today.getDate() - 7));
      case "Last 30 Days":
        return expenseDate >= new Date(today.setDate(today.getDate() - 30));
      case "Last 12 Months":
        return expenseDate.getFullYear() === today.getFullYear();
      case "Last 6 Years":
        return expenseDate.getFullYear() >= today.getFullYear() - 6;
      default:
        return true;
    }
  });
};

const YearlyExpense = ({ project }) => {
  const { duration } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expenseData, setExpenseData] = useState([]);
  const [showIncome, setShowIncome] = useState(true);
  const [showExpense, setShowExpense] = useState(true);
  const [showProfit, setShowProfit] = useState(true);

  const fetchExpenses = async () => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem("token");

    if (!token) {
      setExpenseData([]);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/expenses/getAllExpenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch expenses");
      const expenseData = await response.json();

      const filteredExpenses = project
        ? expenseData.filter((exp) => exp.project === project)
        : expenseData;

      const expensesByDuration = filterExpensesByDuration(
        filteredExpenses,
        duration
      );
      setExpenseData(processYearlyExpenses(expensesByDuration));
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExpenses();
  }, [project, duration]);

  const handleIncomeChange = () => {
    setShowIncome((prev) => !prev);
  };

  const handleExpenseChange = () => {
    setShowExpense((prev) => !prev);
  };

  const handleProfitChange = () => {
    setShowProfit((prev) => !prev);
  };

  if (loading)
    return (
      <div className="flex justify-center items-center h-full">
        <div className="spinner"></div>
      </div>
    );

  if (error) return <p>Error: {error}</p>;

  const showGraph = showIncome || showExpense || showProfit;

  return (
    <div className="rounded-lg bg-white p-4 lg:h-full font-customFont">
      <div className="flex mb-4">
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showIncome}
            onChange={handleIncomeChange}
            className="custom-radioIncome me-1"
          />
          <span className="text-black">Total Income</span>
        </label>
        <label className="flex items-center me-2">
          <input
            type="checkbox"
            checked={showExpense}
            onChange={handleExpenseChange}
            className="custom-radioExpense me-1"
          />
          <span className="text-black">Total Expense</span>
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={showProfit}
            onChange={handleProfitChange}
            className="custom-radioNetIncome me-1"
          />
          <span className="text-black">Total Net Income</span>
        </label>
      </div>

      {expenseData.length === 0 ? (
        <p>No expenses available for the selected duration.</p>
      ) : (
        <>
          {!showGraph ? (
            <p>Please select at least one metric to display.</p>
          ) : (
            <BarChart
              dataset={expenseData}
              xAxis={[{ scaleType: "band", dataKey: "year" }]}
              yAxis={[{ scaleType: "linear" }]}
              series={[
                showIncome && {
                  dataKey: "income",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#4CAF50",
                },
                showExpense && {
                  dataKey: "expense",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#FF5733",
                },
                showProfit && {
                  dataKey: "profit",
                  valueFormatter: (value) => `$${value.toFixed(2)}`,
                  color: "#3F51B5",
                },
              ].filter(Boolean)}
              layout="vertical"
              margin={{ right: 30, left: 60 }}
              height={350}
            />
          )}
        </>
      )}
    </div>
  );
};

export default YearlyExpense;
