import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Empty, Popconfirm, message, Select } from "antd";
import axios from "axios";
import { Link } from "react-router-dom";

const { Option } = Select;
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "PKR":
      return "Rs";
    case "SAR":
      return "﷼";
    case "GBP":
      return "£";
    case "EUR":
      return "€";
    default:
      return currency;
  }
};

const Projects = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [userProject, setUserProject] = useState("All");
  const [userRole, setUserRole] = useState("");
  const [isSubUser, setIsSubUser] = useState(true);
  const [showPriceUSD, setShowPriceUSD] = useState(true);
  const togglePrice = () => setShowPriceUSD(!showPriceUSD);

  const fetchExpenses = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await fetch(`${API_BASE_URL}/expenses/getAllExpenses`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch expenses");
      const expenseData = await response.json();
      console.log("Fetched Expenses:", expenseData);

      const sortedExpenses = expenseData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      const expensesToSet =
        userProject === "All"
          ? sortedExpenses
          : sortedExpenses.filter((expense) => expense.project === userProject);

      setExpenses(expensesToSet);
      setFilteredExpenses(expensesToSet);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/projects/getProjectList`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch projects");
      const data = await response.json();
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        throw new Error("Projects data is not an array");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to load projects");
    }
  };

  const fetchUserProfile = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: userProfile } = await axios.get(
        `${API_BASE_URL}/auth/profile`,
        config
      );
      setUserProject("All");
      setIsSubUser(false);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          const { data: subUserProfile } = await axios.get(
            `${API_BASE_URL}/subUsers/subUserProfile`,
            config
          );
          setUserProject(subUserProfile.project || "");
          setUserRole(subUserProfile.role || "");
          setIsSubUser(true);
        } catch (subUserError) {
          message.error("Failed to fetch sub-user data");
          console.error(subUserError);
        }
      } else {
        message.error("Failed to fetch user profile");
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchUserProfile();
    fetchExpenses();
    fetchProjects();
  }, []);

  useEffect(() => {
    if (userProject !== "") {
      fetchExpenses();
    }
  }, [userProject]);

  const handleRefresh = () => {
    fetchExpenses();
  };

  const calculateTotalIncome = (project) => {
    return filteredExpenses.reduce((acc, expense) => {
      if (expense.type === "Income" && expense.project === project) {
        return acc + (expense.price || 0);
      }
      return acc;
    }, 0);
  };

  const calculateTotalExpenses = (project) => {
    return filteredExpenses.reduce((acc, expense) => {
      if (expense.type === "Expense" && expense.project === project) {
        return acc + (expense.price || 0);
      }
      return acc;
    }, 0);
  };
  const calculateTotalIncomeUSD = (project) => {
    return filteredExpenses.reduce((acc, expense) => {
      if (expense.type === "Income" && expense.project === project) {
        return acc + (expense.priceUSD || 0);
      }
      return acc;
    }, 0);
  };

  const calculateTotalExpensesUSD = (project) => {
    return filteredExpenses.reduce((acc, expense) => {
      if (expense.type === "Expense" && expense.project === project) {
        return acc + (expense.priceUSD || 0);
      }
      return acc;
    }, 0);
  };
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/projects/deleteProject/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        console.error(
          `Delete failed with status ${response.status}: ${response.statusText}`
        );
        throw new Error("Failed to delete project");
      }
      toast.success("Project deleted successfully");
      fetchProjects();
    } catch (error) {
      console.error("Error deleting project:", error);
      toast.error("Failed to delete project");
    }
  };

  const handleUpdate = async (updatedProject) => {
    const token = localStorage.getItem("token");
    const projectId = updatedProject._id;

    try {
      const response = await fetch(
        `${API_BASE_URL}/projects/updateProject/${projectId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedProject),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update project");
      }

      const result = await response.json();
      console.log("Project updated successfully:", result);
      toast.success("Project updated successfully");
      fetchProjects();
      closeModal();
    } catch (error) {
      console.error("Error updating expense:", error);
      toast.error("Error updating expense");
    }
  };

  const openEditModal = (project) => {
    setEditProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditProject(null);
  };

  return (
    <div className="rounded-xl bg-white h-full py-3 px-4 border border-2 border-[#E9EAEB] font-customFont">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Projects</h1>
          <p className="text-xs text-gray-500 font-semibold">
            View and manage your expenses
          </p>
        </div>
        <div className="flex gap-3 items-center mt-2 md:mt-0">
          <button
            onClick={handleRefresh}
            className="text-black border border-[#DCE3E3] px-3 py-1 rounded-md mb-4 md:mb-0"
          >
            <i className="fa-solid fa-arrow-rotate-right text-lg"></i>
          </button>
          <Link to="/panel/addExpense">
            <button className="bg-themeGradient text-white px-2 py-1 rounded-md mb-4 md:mb-0 flex items-center">
              <i className="fa-solid fa-plus text-lg"></i>
              <span className="ms-2">New Transaction</span>
            </button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col md:flex-row md:space-x-3 my-4">
        <div className="flex flex-col md:w-1/6 mb-3 md:mb-0">
          {" "}
          <Select
            defaultValue="Dollar"
            onChange={togglePrice}
            className={`${
              showPriceUSD ? "ring-1 ring-blue-400 rounded-md" : ""
            }`}
          >
            <Option value="Dollar">Dollar</Option>
            <Option value="Custom">Project Currency</Option>
          </Select>
        </div>
      </div>

      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center items-center h-48 mt-20">
            <div class="spinner"></div>
          </div>
        ) : (
          <div className="relative">
            <div className="max-h-[500px] scrollbar-hide">
              <div className="overflow-x-auto overflow-hidden rounded-lg border border-gray-200">
                <div className="min-w-[800px] sm:min-w-full rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-[#E9EAEB] text-[#535862]">
                      <tr>
                        <th className="px-4 py-3 text-left">Project</th>
                        <th className="px-4 py-3 text-left">Start Date</th>
                        <th className="px-4 py-3 text-left">End Date</th>
                        <th className="px-4 py-3 text-left">Income</th>
                        <th className="px-4 py-3 text-left">Expenses</th>
                        <th className="px-4 py-3 text-left">Action</th>
                      </tr>
                    </thead>
                    <tbody className=" text-[#535862]">
                      {projects.length === 0 ? (
                        <tr>
                          <td colSpan="6" className="text-center py-4">
                            <Empty />
                          </td>
                        </tr>
                      ) : (
                        projects.map((project) => {
                          const totalIncomeUSD = calculateTotalIncomeUSD(
                            project.project
                          );
                          const totalExpensesUSD = calculateTotalExpensesUSD(
                            project.project
                          );
                          const totalIncome = calculateTotalIncome(
                            project.project
                          );
                          const totalExpenses = calculateTotalExpenses(
                            project.project
                          );
                          return (
                            <tr key={project._id} className="border-b">
                              <Link
                                to={`/Panel/project/${encodeURIComponent(
                                  project.project
                                )}`}
                              >
                                <td className="px-4 py-4 flex gap-2">
                                  <span>
                                    <img src="../icons/Projects.svg" />
                                  </span>
                                  <span>{project.project}</span>
                                </td>
                              </Link>
                              <td className="px-4 py-4">
                                {new Date(
                                  project.startDate
                                ).toLocaleDateString()}
                              </td>
                              <td className="px-4 py-4">
                                {project.endDate
                                  ? new Date(
                                      project.endDate
                                    ).toLocaleDateString()
                                  : "-"}
                              </td>
                              <td
                                className="px-4 py-4 text-[#00B252]"
                                onClick={togglePrice}
                              >
                                {showPriceUSD ? (
                                  <>
                                    {" $ "}
                                    {totalIncomeUSD.toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {getCurrencySymbol(project.projectCurrency)}{" "}
                                    {totalIncome.toFixed(2)}
                                  </>
                                )}
                              </td>
                              <td
                                className="px-4 py-4 text-[#DB4F00]"
                                onClick={togglePrice}
                              >
                                {showPriceUSD ? (
                                  <>
                                    {" $ "}
                                    {totalExpensesUSD.toFixed(2)}
                                  </>
                                ) : (
                                  <>
                                    {getCurrencySymbol(project.projectCurrency)}{" "}
                                    {totalExpenses.toFixed(2)}
                                  </>
                                )}
                              </td>
                              <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                                {(!isSubUser ||
                                  (isSubUser &&
                                    (userRole === "Edit" ||
                                      userRole === "Admin"))) && (
                                  <div className="flex gap-1">
                                    <img
                                      src="../icons/Edit.svg"
                                      className="cursor-pointer"
                                      onClick={() => openEditModal(project)}
                                      alt="edit icon"
                                    />
                                    <Popconfirm
                                      title="Are you sure you want to delete this expense?"
                                      onConfirm={() =>
                                        handleDelete(project._id)
                                      }
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <img
                                        src="../icons/Delete.svg"
                                        className="cursor-pointer"
                                        alt="delete icon"
                                      />
                                    </Popconfirm>
                                  </div>
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow-lg z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-lg font-semibold mb-4 pb-3 border-b">
          Edit Project
        </h2>
        {editProject && (
          <form
            onSubmit={(e) => {
              e.preventDefault();

              const formData = new FormData(e.target);
              const updatedProject = {
                ...editProject,
                project: formData.get("project"),
                startDate: formData.get("startDate"),
                endDate: formData.get("endDate"),
                projectCurrency: formData.get("projectCurrency"),
              };
              handleUpdate(updatedProject);
            }}
            className="space-y-4"
          >
            <div>
              <label className="block font-medium">Title</label>
              <input
                type="text"
                name="project"
                defaultValue={editProject.project}
                required
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div>
              <label className="block font-medium">Start</label>
              <input
                type="date"
                name="startDate"
                defaultValue={
                  new Date(editProject.startDate).toISOString().split("T")[0]
                }
                required
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>
            <div>
              <label className="block font-medium">End</label>
              <input
                type="date"
                name="endDate"
                defaultValue={
                  editProject.endDate
                    ? new Date(editProject.endDate).toISOString().split("T")[0]
                    : ""
                }
                className="border border-gray-300 rounded-md p-2 w-full"
              />
            </div>

            <div className="flex justify-end space-x-2">
              <button
                type="submit"
                className="bg-themeGradient text-white px-4 py-2 rounded-md"
              >
                Update
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default Projects;
