import React, { useEffect, useState } from "react";
import axios from "axios";
import { message, Input, Select } from "antd";

const { Option } = Select;

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const AddSubUser = ({ onSuccess }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("View");
  const [status, setStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setProjectsLoading(true);
        const response = await fetch(
          `${API_BASE_URL}/projects/getProjectList`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch projects");
        }

        const data = await response.json();
        if (Array.isArray(data)) {
          setProjects(data);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        message.error("Failed to load projects");
      } finally {
        setProjectsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const parentUser = localStorage.getItem("userId");

  if (!parentUser) {
    message.error("You must be logged in to add a sub-user.");
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !password || !role || !status || !project) {
      message.error("Please fill in all fields.");
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      if (!token) {
        message.error("You are not authorized to perform this action.");
        return;
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/subusers/createSubUser`,
        { name, email, project, password, role, status, parentUser },
        config
      );

      if (response.status === 201) {
        message.success("Sub-user added successfully!");
        setName("");
        setEmail("");
        setPassword("");
        setProject("");
        setRole("View");
        setStatus("true");

        if (onSuccess) {
          onSuccess();
        }
      } else {
        message.error("Failed to add sub-user. Please try again.");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(error.response.data.message);
      } else {
        message.error("Failed to add sub-user");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto bg-white">
      <h2 className="text-left text-2xl font-semibold pb-3 text-black border-b ">
        Add Member
      </h2>

      <form onSubmit={handleSubmit}>
        <div className="my-3">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <Input
            id="name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border rounded-lg"
            placeholder="Enter sub-user name"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border rounded-lg"
            placeholder="Enter sub-user email"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <Input.Password
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border rounded-lg"
            placeholder="Enter sub-user password"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 mb-2">
            Project:
            <select
              value={project}
              onChange={(e) => setProject(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
            >
              <option value="">Select Project</option>
              {projectsLoading ? (
                <option value="" disabled>
                  Loading projects...
                </option>
              ) : projects.length === 0 ? (
                <option value="" disabled>
                  No projects found
                </option>
              ) : (
                <>
                  <option value="All">All Projects</option>
                  {projects.map((pro) => (
                    <option key={pro._id} value={pro.project}>
                      {pro.project}
                    </option>
                  ))}
                </>
              )}
            </select>
          </label>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="role"
          >
            Role
          </label>
          <Select
            id="role"
            value={role}
            onChange={(value) => setRole(value)}
            className="w-full rounded-lg"
          >
            <Option value="View">View</Option>
            <Option value="Edit">Edit</Option>
            <Option value="Admin">Admin</Option>
          </Select>
        </div>

        <div className="mb-4 flex items-center border-b pb-3">
          <label className="block text-gray-700 text-sm font-bold  mr-4">
            Status
          </label>
          <div
            className={`relative inline-block w-11 h-5 transition duration-200 ease-linear rounded-full cursor-pointer ${
              status ? "bg-[#00B252]" : "bg-gray-300"
            }`}
            onClick={() => setStatus(!status)}
          >
            <span
              className={`absolute block w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
                status ? "translate-x-6" : "translate-x-0"
              }`}
            ></span>
          </div>
          <span className="ml-2">{status ? "Active" : "Inactive"}</span>
        </div>

        <button
          type="submit"
          className="w-full py-2 bg-themeGradient text-white font-semibold rounded-md shadow-sm hover:bg-themeColor2"
          disabled={loading}
        >
          {loading ? "Adding..." : "Add Member"}
        </button>
      </form>
    </div>
  );
};

export default AddSubUser;
