import { Button, Upload, Modal, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:8011";

const CreateInvoice = () => {
  const [saveLoader, setSaveLoader] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerCompany, setCustomerCompany] = useState("");
  const [note, setNote] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [discount, setDiscount] = useState("");
  const [signature, setSignature] = useState(null);
  const [isTaxModalVisible, setIsTaxModalVisible] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [taxName, setTaxName] = useState("");
  const [taxRate, setTaxRate] = useState("");
  const [isItemModalVisible, setIsItemModalVisible] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [itemName, setItemName] = useState("");
  const [itemQuantity, setItemQuantity] = useState("");
  const [AllItemQuantity, setAllItemQuantity] = useState("");
  const [itemUnitPrice, setItemUnitPrice] = useState("");
  const [itemTotalPrice, setItemTotalPrice] = useState("");
  const [allItemTotalPrice, setAllItemTotalPrice] = useState("");
  const navigate = useNavigate();
  const handleFileChange = (info) => {
    const file = info.fileList[info.fileList.length - 1]?.originFileObj;
    setSignature(file);
  };

  const handleAddTax = () => {
    if (!taxName || !taxRate) {
      message.warning("Please fill in both Tax Name and Tax Rate.");
      return;
    }

    setTaxList([...taxList, { name: taxName, rate: taxRate }]);
    setTaxName("");
    setTaxRate("");
  };

  const handleDeleteTax = (index) => {
    setTaxList(taxList.filter((_, i) => i !== index));
  };

  const handleAddItem = () => {
    if (!itemName || !itemQuantity || !itemUnitPrice) {
      message.warning("Please fill in all fields.");
      return;
    }

    const totalItemPrice = itemQuantity * itemUnitPrice;
    setItemList([
      ...itemList,
      {
        itemName: itemName,
        itemQuantity: itemQuantity,
        itemUnitPrice: itemUnitPrice,
        itemTotalPrice: totalItemPrice,
      },
    ]);
    setItemName("");
    setItemQuantity("");
    setItemUnitPrice("");
    setItemTotalPrice("");
    calculateTotalItems();
    calculateTotalAllItems();
  };

  const handleDeleteItem = (index) => {
    setItemList(itemList.filter((_, i) => i !== index));
  };

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "ExpenseTracker");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dqvgbgje8/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const urlData = await response.json();
      return urlData.url;
    } catch (error) {
      console.error(error);
      message.error("Image upload failed.");
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setSaveLoader("true");
    if (!token) {
      message.error("Please log in to add an expense.");
      navigate("/signIn");
      return;
    }

    if (!customerCompany || itemList.length === 0 || !invoiceDate) {
      message.warning(
        "Please fill in all required fields and add at least one item and tax."
      );
      return;
    }

    let signatureUrl = null;
    if (signature) {
      signatureUrl = await uploadImage(signature);
      if (!signatureUrl) {
        message.error("Failed to upload signature. Please try again.");
        return;
      }
    } else {
      signatureUrl = "";
    }

    const payload = {
      customerName,
      customerCompany,
      note,
      invoiceDate,
      discount,
      signature: signatureUrl,
      currencySymbol,
      items: itemList.map((item) => ({
        itemName: item.itemName,
        itemQuantity: item.itemQuantity,
        itemUnitPrice: item.itemUnitPrice,
        itemTotalPrice: item.itemTotalPrice,
      })),
      taxes: taxList.map((tax) => ({
        name: tax.name,
        rate: parseFloat(tax.rate),
      })),
    };

    fetch(`${API_BASE_URL}/invoice/addInvoice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        message.success("Invoice created successfully!");
        setCustomerName("");
        setCustomerCompany("");
        setNote("");
        setInvoiceDate("");
        setDiscount("");
        setSignature(null);
        setTaxList([]);
        setItemList([]);
        setCurrencySymbol("");
        navigate("/Panel/invoice");
        setSaveLoader("false");
      })
      .catch((error) => {
        console.error("Error:", error);
        message.error("Failed to create invoice. Please try again.");
      });
  };

  const calculateItemTotalPrice = () => {
    const totalPrice = itemQuantity * itemUnitPrice;
    setItemTotalPrice(totalPrice);
  };

  const calculateTotalItems = () => {
    const totalItems = itemList.reduce((acc, item) => {
      return acc + item.itemQuantity * itemList.length;
    }, 0);
    setAllItemQuantity(totalItems);
  };

  const calculateTotalAllItems = () => {
    const totalPrice = itemList.reduce((acc, item) => {
      return acc + item.itemQuantity * item.itemUnitPrice;
    }, 0);
    const taxTotal = taxList.reduce((acc, tax) => {
      return acc + (totalPrice * parseFloat(tax.rate)) / 100;
    }, 0);
    setAllItemTotalPrice(totalPrice + taxTotal);
  };
  useEffect(() => {
    calculateTotalItems();
    calculateTotalAllItems();
  }, [itemList]);
  return (
    <div className="rounded-xl bg-white h-full p-4 border border-2 border-[#E9EAEB] font-customFont">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6">
        <div style={{ fontFamily: "Roboto, sans-serif" }}>
          <h1 className="font-bold text-lg md:text-xl">Create New Invoice</h1>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Customer Name:
                <input
                  type="text"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Customer Company:
                <input
                  type="text"
                  value={customerCompany}
                  onChange={(e) => setCustomerCompany(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                  required
                />
              </label>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Invoice Date:
                <input
                  type="date"
                  value={invoiceDate}
                  onChange={(e) => setInvoiceDate(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                  required
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Discount(%):
                <input
                  type="number"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Signature:
                <Upload
                  listType="picture"
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  className="mt-1 block w-full py-0 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <Button
                    icon={<UploadOutlined />}
                    className="mb-1 bg-transparent border-0 hover:!text-themeColor hover:!bg-transparent hover:text-themeColor2 focus:outline-none"
                  >
                    Upload Image
                  </Button>
                </Upload>
              </label>
            </div>
          </div>

          <div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Currency:
                <select
                  value={currencySymbol}
                  onChange={(e) => setCurrencySymbol(e.target.value)}
                  required
                  className="mt-1 block w-full px-3 py-2.5 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  <option value="">Select Currency</option>
                  <option value="$">$</option>
                  <option value="£">£</option>
                  <option value="€">€</option>
                  <option value="﷼">﷼</option>
                  <option value="Rs">Rs</option>
                </select>
              </label>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Items:
                <div
                  onClick={() => setIsItemModalVisible(true)}
                  icon={<PlusOutlined />}
                  className="mt-1 block w-full px-3 py-2 text-center border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  Add Item
                </div>
              </label>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="font-semibold">Total Items:</span>
                  <span>{parseFloat(AllItemQuantity).toFixed(2)}</span>
                </div>

                <div className="flex justify-between items-center mt-2 font-bold">
                  <span>Total Amount:</span>
                  <span>{parseFloat(allItemTotalPrice).toFixed(2)}</span>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 mb-2">
                Tax:
                <div
                  onClick={() => setIsTaxModalVisible(true)}
                  icon={<PlusOutlined />}
                  className="mt-1 block w-full px-3 py-2 text-center border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
                >
                  Add Tax
                </div>
              </label>
              <ul className="mt-2">
                {taxList.length > 0 &&
                  taxList.map((tax, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      {tax.name} - {tax.rate}%
                      <Button
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeleteTax(index)}
                        className="text-red-500"
                      />
                    </li>
                  ))}
              </ul>

              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <span className="font-semibold">Subtotal:</span>
                  <span>
                    {allItemTotalPrice
                      ? parseFloat(allItemTotalPrice).toFixed(2)
                      : "0.00"}
                  </span>
                </div>

                {taxList.map((tax, index) => {
                  const taxValue = (
                    (parseFloat(itemTotalPrice) * parseFloat(tax.rate)) /
                    100
                  ).toFixed(2);
                  return (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span className="font-semibold">
                        {tax.name} ({tax.rate}%):
                      </span>
                      <span>{taxValue}</span>
                    </div>
                  );
                })}

                <div className="flex justify-between items-center mt-2 font-bold">
                  <span>Total:</span>
                  <span>
                    {allItemTotalPrice && !isNaN(parseFloat(allItemTotalPrice))
                      ? (
                          parseFloat(allItemTotalPrice) +
                          taxList.reduce((acc, tax) => {
                            return (
                              acc +
                              (parseFloat(allItemTotalPrice) *
                                parseFloat(tax.rate)) /
                                100
                            );
                          }, 0)
                        ).toFixed(2)
                      : "0.00"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 font-medium mb-2">
            Note:
            <textarea
              id="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-gray-100 rounded-md shadow-sm focus:outline-none focus:ring-themeColor focus:border-themeColor"
              rows="4"
            />
          </label>
        </div>

        <div className="text-center mt-10">
          <button
            type="submit"
            disabled={saveLoader}
            className={`py-2 px-6 w-40 bg-themeGradient text-white font-semibold rounded-md shadow-sm hover:bg-themeColor2  ${
              saveLoader ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {saveLoader ? "Creating..." : "Create"}
          </button>
        </div>
      </form>

      <Modal
        title="Add Tax"
        visible={isTaxModalVisible}
        onCancel={() => setIsTaxModalVisible(false)}
        footer={null}
      >
        <div className="mb-4">
          <Input
            placeholder="Tax Name"
            value={taxName}
            onChange={(e) => setTaxName(e.target.value)}
            className="mb-2"
          />
          <Input
            type="number"
            placeholder="Tax Rate (%)"
            value={taxRate}
            onChange={(e) => setTaxRate(e.target.value)}
            className="mb-4"
            min="0"
            step="0.01"
          />
          <Button type="primary" onClick={handleAddTax} block>
            Add Tax
          </Button>
        </div>

        <ul className="mt-4">
          {taxList.map((tax, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              {tax.name} - {tax.rate}%
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteTax(index)}
                className="text-red-500"
              />
            </li>
          ))}
        </ul>
      </Modal>
      <Modal
        title="Add Item"
        visible={isItemModalVisible}
        onCancel={() => setIsItemModalVisible(false)}
        footer={null}
      >
        <div className="mb-4">
          <Input
            placeholder="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            className="mb-2"
          />
          <Input
            type="number"
            placeholder="Quantity"
            value={itemQuantity}
            onChange={(e) => setItemQuantity(e.target.value)}
            className="mb-4"
          />
          <Input
            type="number"
            placeholder="Unit Price"
            value={itemUnitPrice}
            onChange={(e) => setItemUnitPrice(e.target.value)}
            className="mb-2"
          />
          <div className="mb-2 border rounded-md px-2 py-1 text-gray-400">
            {itemUnitPrice * itemQuantity}
          </div>

          <Button
            type="primary"
            onClick={() => {
              handleAddItem();
              calculateItemTotalPrice();
            }}
            block
          >
            Add Item
          </Button>
        </div>

        <ul className="mt-4">
          {itemList.map((item, index) => (
            <li key={index} className="flex justify-between items-center mb-2">
              {item.itemName} - {item.itemQuantity} - {item.itemUnitPrice} -{" "}
              {item.itemTotalPrice}
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteItem(index)}
                className="text-red-500"
              />
            </li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

export default CreateInvoice;
